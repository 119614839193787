/*  =====================
	=== Page Layout ===
	===================== */
.animation-page {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	width: 100%;
}

.animation-content-wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-inline: 20px;
	z-index: 1;
}

/*  ======================
	=== Artists Layout ===
	====================== */
.animation-page-artists,
.animation-page-but-here-artists,
.animation-page-recommended-artists {
	background-color: #8449a6;
}

/*  ====================
	=== Songs Layout ===
	==================== */
.animation-page-songs,
.animation-page-but-here-songs,
.animation-page-recommended-songs {
	background-color: #9a2f5a;
}

/*  =====================
	=== Button Layout ===
	===================== */
.buttons {
	bottom: 30px;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	display: flex;
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: auto;
	margin-top: 30px;
	position: absolute;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.buttons-three {
	grid-template-columns: 1fr 1fr 1fr;
	margin-top: 50px;
	position: static;
}

/*  ===============
	=== Buttons ===
	===============*/
.share-button {
	color: black;
}

.back-button {
	color: black !important;
	position: absolute;
	right: 30px;
	top: 25px;
}

.animation-button {
	align-items: center;
	background-color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	font-size: 16px;
	font-weight: 600;
	height: 45px;
	justify-content: center;
	min-width: 45px;
	padding: 0;
	transition: all 0.2s;
}

.redo-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.redo-button:hover,
.animation-button:hover {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

/*  =============
	=== Icons ===
	============= */
.carret {
	width: 20px;
}

.redo-icon {
	height: 20px;
	width: 20px;
}

/*  ==================
	=== Animations ===
	================== */
.fade-in {
	-webkit-animation: fade-in 0.5s ease-in-out forwards;
	        animation: fade-in 0.5s ease-in-out forwards;
}

@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* screen 2 */
.animation-page-genres {
	background-color: #dff796;
}

.screen2-content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.title-container {
	position: relative;
}

.screen2-title {
	font-size: 30px;
	line-height: 35px;
	text-align: center;
	color: #51365b;
	text-transform: uppercase;
}

.genre-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	color: white;
	display: grid;
	/* grid-template-columns: 1fr; */
	grid-template-rows: 95px 95px 95px 95px 95px;
	grid-row-gap: 0px;
	row-gap: 0px;
	margin-top: 25px;
	margin-bottom: 20px;
}

.genre-wrapper {
	display: flex;
	align-items: center;
	overflow: hidden;
}

.genre-info,
.genre-number {
	font-size: 20px;
	font-weight: 700;
	text-transform: capitalize;
	color: #51365b;
}

.genre-number {
	font-size: 24px;
}

.genre-text-wrapper {
	display: flex;
	flex-direction: column;
	-webkit-transform: translateY(12px);
	        transform: translateY(12px);
}

.genre-text {
	font-weight: 800;
	font-size: 16px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	line-height: 15px;
	height: 15px;
	-webkit-transform: scaley(400%);
	        transform: scaley(400%);
	padding-inline: 5px;
	margin-left: 15px;
	text-transform: uppercase;
	white-space: nowrap;
	max-width: 115px;
	overflow: hidden;
}

.genre-info {
	max-width: 115px;
	margin-left: 15px;
	margin-top: 20px;
	white-space: nowrap;
	overflow: hidden;
}

.genre-text-1 {
	background-color: #a70303;
	color: #edc8f8;
}

.genre-text-2 {
	background-color: #0000da;
	color: #80f2d2;
}

.genre-text-3 {
	background-color: #36cb00;
	color: #f3d3cb;
}

.genre-text-4 {
	background-color: #ffa200;
	color: #c8ebf6;
}

.genre-text-5 {
	background-color: #69007b;
	color: #f2ef9e;
}

.genre-wrapper-1 {
	-webkit-animation: slide-in 0.6s ease-in-out 0.2s forwards;
	        animation: slide-in 0.6s ease-in-out 0.2s forwards;
	-webkit-transform: translateX(20vw);
	        transform: translateX(20vw);
	opacity: 0;
}

.genre-wrapper-2 {
	-webkit-animation: slide-in 0.6s ease-in-out 0.3s forwards;
	        animation: slide-in 0.6s ease-in-out 0.3s forwards;
	-webkit-transform: translateX(20vw);
	        transform: translateX(20vw);
	opacity: 0;
}

.genre-wrapper-3 {
	-webkit-animation: slide-in 0.6s ease-in-out 0.4s forwards;
	        animation: slide-in 0.6s ease-in-out 0.4s forwards;
	-webkit-transform: translateX(20vw);
	        transform: translateX(20vw);
	opacity: 0;
}

.genre-wrapper-4 {
	-webkit-animation: slide-in 0.6s ease-in-out 0.5s forwards;
	        animation: slide-in 0.6s ease-in-out 0.5s forwards;
	-webkit-transform: translateX(20vw);
	        transform: translateX(20vw);
	opacity: 0;
}

.genre-wrapper-5 {
	-webkit-animation: slide-in 0.6s ease-in-out 0.6s forwards;
	        animation: slide-in 0.6s ease-in-out 0.6s forwards;
	-webkit-transform: translateX(20vw);
	        transform: translateX(20vw);
	opacity: 0;
}

@-webkit-keyframes slide-in {
	0% {
		-webkit-transform: translateX(20vw);
		        transform: translateX(20vw);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes slide-in {
	0% {
		-webkit-transform: translateX(20vw);
		        transform: translateX(20vw);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
		opacity: 1;
	}
}

@media (min-width: 350px) {
	.screen2-title {
		font-size: 40px;
		line-height: 40px;
	}

	.genre-text,
	.genre-info {
		max-width: 160px;
	}
}

@media (min-width: 450px) {
	.genre-text,
	.genre-info {
		max-width: 260px;
	}
}

@media (min-width: 650px) {
	.genre-list {
		grid-template-rows: 105px 105px 105px 105px 105px;
	}

	.genre-text-wrapper {
		-webkit-transform: translateY(12px);
		        transform: translateY(12px);
	}

	.genre-text {
		font-size: 20px;
		line-height: 18px;
		height: 18px;
		max-width: 300px;
	}

	.genre-info {
		margin-top: 22px;
	}
}

@media (min-width: 1000px) {
	.screen2-title {
		font-size: 60px;
		line-height: 60px;
	}

	.genre-text {
		font-size: 28px;
		line-height: 24px;
		height: 23px;
		max-width: 280px;
		margin-left: 20px;
	}

	.genre-list {
		grid-template-rows: 100px 100px 100px 100px 100px;
		grid-row-gap: 10px;
		row-gap: 10px;
	}

	.genre-number {
		font-size: 30px;
	}

	.genre-text-wrapper {
		height: 100%;
		flex-direction: row;
		-webkit-transform: translateY(35px);
		        transform: translateY(35px);
	}

	.genre-info {
		margin-top: 0px;
		font-size: 22px;
	}
}

/* screen 3 */
.screen3-content {
	/* background-color: aliceblue; */
}

.screen3-title {
	font-size: 30px;
	line-height: 35px;
	text-align: center;
	color: rgb(240, 217, 255);
	text-transform: uppercase;
	margin: auto;
	margin-bottom: 20px;
	width: 240px;
}

/* Grid */
.artist-grid {
	display: grid;
	max-width: 200px;
	max-height: 550px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	grid-template:
		'top-artist'
		'remaining-artist'
		'names';
	grid-template-columns: 1fr;
	grid-template-rows: 2fr 2fr 2fr;
	margin: auto;
}

.one-match {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: -webkit-fit-content !important;
	height: -moz-fit-content !important;
	height: fit-content !important;
}

.top-artist {
	grid-area: top-artist;
	max-width: 100%;
	max-height: 100%;
	/* background-color: #51365b; */
	margin-bottom: 10px;
}

.remaining-artist {
	grid-area: remaining-artist;
	/* background-color: green; */
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.artist {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	/* border: 1px solid red; */
	/* background-color: rgb(77, 118, 118); */
	/* padding-block: 5px; */
}

.remaining-artist-img {
	height: 85px;
	border-radius: 4%;
}

.artist-img-2 {
	border: 5px solid rgb(1, 190, 44);
	-webkit-transform: rotate(-10deg) translateX(5px);
	        transform: rotate(-10deg) translateX(5px);
}

.artist-img-3 {
	border: 5px solid rgb(255, 144, 0);
	-webkit-transform: rotate(15deg) translateX(-5px);
	        transform: rotate(15deg) translateX(-5px);
}

.artist-img-4 {
	border: 5px solid rgb(255, 216, 0);
	-webkit-transform: rotate(15deg) translateX(5px);
	        transform: rotate(15deg) translateX(5px);
}

.artist-img-5 {
	border: 5px solid rgb(211, 15, 65);
	-webkit-transform: rotate(-10deg) translateX(-5px);
	        transform: rotate(-10deg) translateX(-5px);
}

.names {
	margin-top: 20px;
	grid-area: names;
	display: grid;
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
}

.one-match-names {
	grid-template-rows: 1fr;
}

/* Top Artist */
.top-artists-wrapper {
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.top-artist-img-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 70%;
	position: absolute;
	overflow: hidden;
}

.top-artist-img {
	object-fit: cover;
}

.sun-burst-wrapper {
	width: 100%;
	height: 100%;
}

.top-artist-burst {
	width: 100%;
	height: 100%;
}

/* Artist Names */
.artist-name-wrapper {
	height: 100%;
	display: flex;
}

.artist-name-wrapper-0 {
	background-color: #b1c7ff;
}

.artist-name-wrapper-1 {
	background-color: #02bd2e;
}

.artist-name-wrapper-2 {
	background-color: #ff9000;
}

.artist-name-wrapper-3 {
	background-color: #ffd800;
}

.artist-name-wrapper-4 {
	background-color: #000000;
	color: #ffd800;
}

.artist-number {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	font-weight: 700;
	font-size: 20px;
}

.artist-name {
	display: flex;
	align-items: center;
	font-weight: 700;
	white-space: nowrap;
	font-size: 18px;
	max-width: 180px;
	overflow: hidden;
}

.one-match .artist-name,
.one-match .artist-number {
	font-size: 22px;
}

@media (min-width: 350px) {
	.screen3-title {
		font-size: 35px;
		line-height: 35px;
	}
}

@media (min-width: 400px) {
	.artist-grid {
		max-width: 275px;
		height: 550px;
		max-height: 550px;
		grid-template-rows: 3fr 1fr 2fr;
	}

	.top-artist {
		margin-bottom: 0px;
	}

	.remaining-artist {
		display: flex;
	}

	.remaining-artist-img {
		height: 70px;
		border-width: 4px;
	}

	.artist-img-2 {
		-webkit-transform: rotate(-15deg);
		        transform: rotate(-15deg);
	}

	.artist-img-3 {
		-webkit-transform: rotate(15deg);
		        transform: rotate(15deg);
	}

	.artist-img-4 {
		-webkit-transform: rotate(-15deg);
		        transform: rotate(-15deg);
	}

	.artist-img-5 {
		-webkit-transform: rotate(15deg);
		        transform: rotate(15deg);
	}

	.names {
		margin-top: 15px;
	}

	.artist-number {
		font-size: 22px;
	}

	.artist-name {
		font-size: 20px;
		max-width: 245px;
	}

	.one-match .artist-name,
	.one-match .artist-number {
		font-size: 26px;
	}
}

@media (min-width: 600px) {
	.screen3-title {
		font-size: 50px;
		line-height: 55px;
		width: 480px;
	}

	.artist-grid {
		max-width: 500px;
		height: 500px;
		max-height: 500px;
		grid-template:
			'top-artist remaining-artist'
			'names remaining-artist';
		grid-template-columns: 4fr 2fr;
		grid-template-rows: 2fr 1fr;
	}

	.one-match {
		max-width: 400px;
	}

	.remaining-artist {
		flex-direction: column;
	}

	.remaining-artist-img {
		height: 110px;
		border-width: 8px;
	}

	.artist-name {
		max-width: 303px;
	}
}

@media (min-width: 1000px) {
	.screen3-title {
		font-size: 60px;
		line-height: 45px;
		width: auto;
		margin-bottom: 40px;
	}
}

.animation-page-no-match {
	background-color: #a1c2d1;
}

.recommened-page-content {
	max-width: 100%;
}

.recommend-page-text {
	text-align: center;
}

.no-match-word {
	font-size: min(10vw, 60px);
	line-height: min(10vw, 60px);
	font-weight: 800;
	text-transform: uppercase;
	color: white;
	opacity: 0;
}

.color-word-no {
	color: #566f8e;
}

@media (min-width: 600px) {
	.recommened-page-content {
		max-width: 600px;
	}
}

/*  ===================
	=== Text layout ===
	=================== */
.recommened-page-content {
	max-width: 100%;
}

.recommend-page-text {
	text-align: center;
}

/*  ============
	=== Text ===
	============ */
.word {
	color: rgb(240, 217, 255);
	font-size: min(10vw, 60px);
	font-weight: 800;
	line-height: min(10vw, 60px);
	opacity: 0;
	text-transform: uppercase;
}

.artists-color {
	color: rgb(229, 147, 191) !important;
}

.song-color {
	color: #dafbff !important;
}

@media (min-width: 600px) {
	.recommened-page-content {
		max-width: 600px;
	}
}

.recommended-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.recommended-title {
	font-size: 25px;
	line-height: 30px;
	text-align: center;
	margin-bottom: 20px;
	color: rgb(240, 217, 255);
	text-transform: uppercase;
}

.recommended-songs-title {
	color: #ffd1eb;
}

.recommended-grid {
	display: grid;
	grid-template-rows: repeat(5, 1fr);
	grid-row-gap: 15px;
	row-gap: 15px;
	margin: auto;
	width: 100%;
}

.recommended-songs {
	padding: 10px;
	border-radius: 10px;
	transition: all 0.05s;
	cursor: pointer;
}

.play-icon {
	width: 40px;
	height: 40px;
	margin-left: auto;
	padding: 10px;
	border-radius: 50%;
	background-color: #5a1732;
	cursor: pointer;
}

.play-icon:hover {
	background-color: #6e203f;
}

.recommended-songs:hover {
	background-color: #83284d;
}

.recommended-item-wrapper {
	display: grid;
	grid-template-columns: 40px 60px 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	color: rgb(0, 0, 0);
	width: 200px;
	opacity: 0;
	margin: auto;
}

.recommended-item-wrapper-0 {
	-webkit-transform: translateX(10vw);
	        transform: translateX(10vw);
	-webkit-animation: slide-in-right 0.5s ease-in-out 0.2s forwards;
	        animation: slide-in-right 0.5s ease-in-out 0.2s forwards;
}

.recommended-item-wrapper-1 {
	-webkit-transform: translateX(-10vw);
	        transform: translateX(-10vw);
	-webkit-animation: slide-in-left 0.5s ease-in-out 0.3s forwards;
	        animation: slide-in-left 0.5s ease-in-out 0.3s forwards;
}

.recommended-item-wrapper-2 {
	-webkit-transform: translateX(10vw);
	        transform: translateX(10vw);
	-webkit-animation: slide-in-right 0.5s ease-in-out 0.4s forwards;
	        animation: slide-in-right 0.5s ease-in-out 0.4s forwards;
}

.recommended-item-wrapper-3 {
	-webkit-transform: translateX(-10vw);
	        transform: translateX(-10vw);
	-webkit-animation: slide-in-left 0.5s ease-in-out 0.5s forwards;
	        animation: slide-in-left 0.5s ease-in-out 0.5s forwards;
}

.recommended-item-wrapper-4 {
	-webkit-transform: translateX(10vw);
	        transform: translateX(10vw);
	-webkit-animation: slide-in-right 0.5s ease-in-out 0.6s forwards;
	        animation: slide-in-right 0.5s ease-in-out 0.6s forwards;
}

.recommended-index {
	font-size: 25px;
	font-weight: 700;
	width: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	color: rgb(255, 222, 233);
	display: flex;
	justify-content: center;
	align-items: center;
}

.recommended-img-wrapper {
	height: 60px;
	width: 60px;
	overflow: hidden;
	position: relative;
}

.recommended-img-wrapper-0 {
	border: 5px solid rgb(229, 147, 191);
}

.recommended-img-wrapper-1 {
	border: 5px solid rgb(1, 190, 44);
}

.recommended-img-wrapper-2 {
	border: 5px solid rgb(255, 144, 0);
}

.recommended-img-wrapper-3 {
	border: 5px solid rgb(255, 216, 0);
}

.recommended-img-wrapper-4 {
	border: 5px solid rgb(211, 15, 65);
}

.recommended-img {
	object-fit: cover;
	position: absolute;
}

.recommended-data-wrapper {
	overflow: hidden;
	display: flex;
	align-items: center;
	position: relative;
}

.recommended-data {
	position: absolute;
	font-size: 22px;
	line-height: 25px;
	font-weight: 700;
	color: rgb(255, 222, 233);
}

.recommended-song-index {
	color: #dafbff;
}

.recommended-song-img-wrapper-0 {
	border: 5px solid #1200ff;
}
.recommended-song-img-wrapper-1 {
	border: 5px solid #f15aff;
}
.recommended-song-img-wrapper-2 {
	border: 5px solid #6826b2;
}
.recommended-song-img-wrapper-3 {
	border: 5px solid #d30f41;
}
.recommended-song-img-wrapper-4 {
	border: 5px solid #e593bf;
}

.recommended-song-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color: #dafbff;
}

.recommended-song-name {
	font-size: 22px;
	line-height: 25px;
	font-weight: 700;
	white-space: nowrap;
	cursor: pointer;
	text-decoration: none;
	color: white;
}

.recommended-song-name:hover {
	text-decoration: underline;
}

.recommended-artist-name {
	font-size: 14px;
}

.play-bar {
	margin-top: 20px;
	margin-inline: auto;
	display: flex;
	justify-content: center;
	width: 230px;
}

@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(10vw);
		        transform: translateX(10vw);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(10vw);
		        transform: translateX(10vw);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
		opacity: 1;
	}
}

@-webkit-keyframes slide-in-left {
	0% {
		-webkit-transform: translateX(-10vw);
		        transform: translateX(-10vw);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
		opacity: 1;
	}
}

@keyframes slide-in-left {
	0% {
		-webkit-transform: translateX(-10vw);
		        transform: translateX(-10vw);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
		opacity: 1;
	}
}

@media (min-width: 300px) {
	.recommended-title {
		font-size: 32px;
		line-height: 35px;
	}

	.play-bar {
		width: 250px;
	}
}

@media (min-width: 350px) {
	.recommended-title {
		font-size: 38px;
		line-height: 42px;
	}

	.recommended-item-wrapper {
		grid-template-columns: 40px 65px 1fr;
		width: 300px;
	}

	.recommended-img-wrapper {
		height: 65px;
		width: 65px;
	}

	.play-bar {
		width: auto;
	}
}

@media (min-width: 400px) {
	.recommended-title {
		font-size: 45px;
		line-height: 50px;
	}

	.recommended-item-wrapper {
		grid-template-columns: 50px 70px 1fr;
		width: 350px;
	}

	.recommended-index {
		font-size: 30px;
		width: 50px;
	}

	.recommended-img-wrapper {
		height: 70px;
		width: 70px;
	}

	.recommended-data {
		font-size: 25px;
	}
}

@media (min-width: 480px) {
	.recommended-title {
		font-size: 50px;
		line-height: 55px;
	}

	.recommended-item-wrapper {
		grid-template-columns: 50px 80px 1fr;
		width: 400px;
	}
}

@media (min-width: 737px) {
	.recommended-content {
	}

	.recommended-grid {
		max-width: 550px;
		grid-row-gap: 15px;
		row-gap: 15px;
	}

	.recommended-title {
		font-size: 60px;
		line-height: 65px;
	}

	.recommended-item-wrapper {
		grid-template-columns: 50px 90px 1fr;
		grid-column-gap: 20px;
		-webkit-column-gap: 20px;
		        column-gap: 20px;
		width: 100%;
	}

	.recommended-song-index {
		font-size: 30px;
		width: 50px;
	}

	.recommended-index {
		font-size: 35px;
	}

	.recommended-img-wrapper {
		height: 80px;
		width: 80px;
	}

	.recommended-data {
		font-size: 30px;
	}
}

/* screen 1 */
.screen1-content {
}

.animation-page-intro {
	background-color: #adefc4;
}

.screen1-text {
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;
	color: white;
	font-size: 28px;
	line-height: 30px;
}

@media (min-width: 300px) {
	.screen1-text {
		font-size: 30px;
		line-height: 35px;
	}
}

@media (min-width: 400px) {
	.screen1-text {
		font-size: 40px;
		line-height: 45px;
	}
}

@media (min-width: 480px) {
	.screen1-text {
		font-size: 50px;
		line-height: 55px;
	}
}

@media (min-width: 650px) {
	.screen1-text {
		font-size: 55px;
		line-height: 55px;
	}
}

.top-fade-in {
	opacity: 0;
	-webkit-animation: fade-in 0.5s ease-in-out forwards;
	        animation: fade-in 0.5s ease-in-out forwards;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
}

.bottom-fade-in {
	opacity: 0;
	-webkit-animation: fade-in 0.5s ease-in-out forwards;
	        animation: fade-in 0.5s ease-in-out forwards;
	-webkit-animation-delay: 2s;
	        animation-delay: 2s;
}

.img-container {
	width: 100%;
	margin-block: 8%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.left-img {
	-webkit-transform: translateX(8%);
	        transform: translateX(8%);
}

.right-img {
	-webkit-transform: translateX(-8%);
	        transform: translateX(-8%);
	z-index: 1;
}

.profile-img {
	width: 100%;
}

.img-wrapper {
	width: 115px;
	height: 115px;
	position: relative;
}

.circle {
	position: absolute;
	width: 83%;
	height: 83%;
	background-color: #233360;
	border-radius: 50%;
	overflow: hidden;
}

/* Left */
.left-img .circle-1 {
	top: 0;
	right: 0;
	z-index: 5;
	border: 6px solid #fca5ff;
}

.left-img .circle-2 {
	top: 0px;
	right: 0px;
	z-index: 4;
	-webkit-animation: left-telescope-2 0.1s ease-in-out forwards;
	        animation: left-telescope-2 0.1s ease-in-out forwards;
	-webkit-animation-delay: 0.1s;
	        animation-delay: 0.1s;
	opacity: 0;
}

@-webkit-keyframes left-telescope-2 {
	from {
		top: 0px;
		right: 0px;
		opacity: 0;
	}
	to {
		top: 3.4%;
		right: 3.4%;
		opacity: 0.8;
	}
}

@keyframes left-telescope-2 {
	from {
		top: 0px;
		right: 0px;
		opacity: 0;
	}
	to {
		top: 3.4%;
		right: 3.4%;
		opacity: 0.8;
	}
}

.left-img .circle-3 {
	top: 10px;
	right: 10px;
	z-index: 3;
	-webkit-animation: left-telescope-3 0.1s ease-in-out forwards;
	        animation: left-telescope-3 0.1s ease-in-out forwards;
	-webkit-animation-delay: 0.2s;
	        animation-delay: 0.2s;
	opacity: 0;
}

@-webkit-keyframes left-telescope-3 {
	from {
		top: 3.4%;
		right: 3.4%;
		opacity: 0;
	}
	to {
		top: 6.8%;
		right: 6.8%;
		opacity: 0.6;
	}
}

@keyframes left-telescope-3 {
	from {
		top: 3.4%;
		right: 3.4%;
		opacity: 0;
	}
	to {
		top: 6.8%;
		right: 6.8%;
		opacity: 0.6;
	}
}

.left-img .circle-4 {
	top: 20px;
	right: 20px;
	z-index: 2;
	-webkit-animation: left-telescope-4 0.1s ease-in-out forwards;
	        animation: left-telescope-4 0.1s ease-in-out forwards;
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
	opacity: 0;
}

@-webkit-keyframes left-telescope-4 {
	from {
		top: 6.8%;
		right: 6.8%;
		opacity: 0;
	}
	to {
		top: 10.2%;
		right: 10.2%;
		opacity: 0.4;
	}
}

@keyframes left-telescope-4 {
	from {
		top: 6.8%;
		right: 6.8%;
		opacity: 0;
	}
	to {
		top: 10.2%;
		right: 10.2%;
		opacity: 0.4;
	}
}

.left-img .circle-5 {
	top: 30px;
	right: 30px;
	opacity: 0.2;
	z-index: 1;
	-webkit-animation: left-telescope-5 0.1s ease-in-out forwards;
	        animation: left-telescope-5 0.1s ease-in-out forwards;
	-webkit-animation-delay: 0.4s;
	        animation-delay: 0.4s;
	opacity: 0;
}

@-webkit-keyframes left-telescope-5 {
	from {
		top: 10.2%;
		right: 10.2%;
		opacity: 0;
	}
	to {
		top: 13.6%;
		right: 13.6%;
		opacity: 0.2;
	}
}

@keyframes left-telescope-5 {
	from {
		top: 10.2%;
		right: 10.2%;
		opacity: 0;
	}
	to {
		top: 13.6%;
		right: 13.6%;
		opacity: 0.2;
	}
}

.left-img .circle-6 {
	top: 40px;
	right: 40px;
	opacity: 0.1;
	-webkit-animation: left-telescope-6 0.1s ease-in-out forwards;
	        animation: left-telescope-6 0.1s ease-in-out forwards;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
	opacity: 0;
}

@-webkit-keyframes left-telescope-6 {
	from {
		top: 13.6%;
		right: 13.6%;
		opacity: 0;
	}
	to {
		top: 17%;
		right: 17%;
		opacity: 0.1;
	}
}

@keyframes left-telescope-6 {
	from {
		top: 13.6%;
		right: 13.6%;
		opacity: 0;
	}
	to {
		top: 17%;
		right: 17%;
		opacity: 0.1;
	}
}

/* Right */
.right-img .circle-1 {
	bottom: 0;
	left: 0;
	z-index: 5;
	border: 6px solid #feff97;
}

.right-img .circle-2 {
	bottom: 0px;
	left: 0px;
	z-index: 4;
	-webkit-animation: right-telescope-2 0.1s ease-in-out forwards;
	        animation: right-telescope-2 0.1s ease-in-out forwards;
	-webkit-animation-delay: 0.1s;
	        animation-delay: 0.1s;
	opacity: 0;
}

@-webkit-keyframes right-telescope-2 {
	from {
		bottom: 0px;
		left: 0px;
		opacity: 0;
	}
	to {
		bottom: 3.4%;
		left: 3.4%;
		opacity: 0.8;
	}
}

@keyframes right-telescope-2 {
	from {
		bottom: 0px;
		left: 0px;
		opacity: 0;
	}
	to {
		bottom: 3.4%;
		left: 3.4%;
		opacity: 0.8;
	}
}

.right-img .circle-3 {
	bottom: 10px;
	left: 10px;
	z-index: 3;
	-webkit-animation: right-telescope-3 0.1s ease-in-out forwards;
	        animation: right-telescope-3 0.1s ease-in-out forwards;
	-webkit-animation-delay: 0.2s;
	        animation-delay: 0.2s;
	opacity: 0;
}

@-webkit-keyframes right-telescope-3 {
	from {
		bottom: 3.4%;
		left: 3.4%;
		opacity: 0;
	}
	to {
		bottom: 6.8%;
		left: 6.8%;
		opacity: 0.6;
	}
}

@keyframes right-telescope-3 {
	from {
		bottom: 3.4%;
		left: 3.4%;
		opacity: 0;
	}
	to {
		bottom: 6.8%;
		left: 6.8%;
		opacity: 0.6;
	}
}

.right-img .circle-4 {
	bottom: 20px;
	left: 20px;
	z-index: 2;
	-webkit-animation: right-telescope-4 0.1s ease-in-out forwards;
	        animation: right-telescope-4 0.1s ease-in-out forwards;
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
	opacity: 0;
}

@-webkit-keyframes right-telescope-4 {
	from {
		bottom: 6.8%;
		left: 6.8%;
		opacity: 0;
	}
	to {
		bottom: 10.2%;
		left: 10.2%;
		opacity: 0.4;
	}
}

@keyframes right-telescope-4 {
	from {
		bottom: 6.8%;
		left: 6.8%;
		opacity: 0;
	}
	to {
		bottom: 10.2%;
		left: 10.2%;
		opacity: 0.4;
	}
}

.right-img .circle-5 {
	bottom: 30px;
	left: 30px;
	z-index: 1;
	-webkit-animation: right-telescope-5 0.1s ease-in-out forwards;
	        animation: right-telescope-5 0.1s ease-in-out forwards;
	-webkit-animation-delay: 0.4s;
	        animation-delay: 0.4s;
	opacity: 0;
}

@-webkit-keyframes right-telescope-5 {
	from {
		bottom: 10.2%;
		left: 10.2%;
		opacity: 0;
	}
	to {
		bottom: 13.6%;
		left: 13.6%;
		opacity: 0.2;
	}
}

@keyframes right-telescope-5 {
	from {
		bottom: 10.2%;
		left: 10.2%;
		opacity: 0;
	}
	to {
		bottom: 13.6%;
		left: 13.6%;
		opacity: 0.2;
	}
}

.right-img .circle-6 {
	bottom: 40px;
	left: 40px;
	opacity: 0.1;
	-webkit-animation: right-telescope-6 0.1s ease-in-out forwards;
	        animation: right-telescope-6 0.1s ease-in-out forwards;
	-webkit-animation-delay: 0.5s;
	        animation-delay: 0.5s;
	opacity: 0;
}

@-webkit-keyframes right-telescope-6 {
	from {
		bottom: 13.6%;
		left: 13.6%;
		opacity: 0;
	}
	to {
		bottom: 17%;
		left: 17%;
		opacity: 0.1;
	}
}

@keyframes right-telescope-6 {
	from {
		bottom: 13.6%;
		left: 13.6%;
		opacity: 0;
	}
	to {
		bottom: 17%;
		left: 17%;
		opacity: 0.1;
	}
}

.star-container {
	width: 20%;
	position: absolute;
}

.left-img .star-container {
	top: 0;
	left: 0;
	/* filter: invert(94%) sepia(99%) saturate(441%) hue-rotate(340deg) brightness(107%) contrast(101%); */
	-webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(173deg)
		brightness(102%) contrast(102%);
	        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(173deg)
		brightness(102%) contrast(102%);
}

.right-img .star-container {
	bottom: 0;
	right: 0;
	-webkit-transform: scaleX(-100%);
	        transform: scaleX(-100%);
	/* filter: invert(89%) sepia(42%) saturate(4800%) hue-rotate(232deg) brightness(105%) contrast(101%); */
	-webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(173deg)
		brightness(102%) contrast(102%);
	        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(173deg)
		brightness(102%) contrast(102%);
}

@media (min-width: 300px) {
	.img-wrapper {
		width: 130px;
		height: 130px;
	}
}

@media (min-width: 350px) {
	.img-wrapper {
		width: 150px;
		height: 150px;
	}

	.circle-1 {
		border-width: 7px !important;
	}
}

@media (min-width: 400px) {
	.img-wrapper {
		width: 180px;
		height: 180px;
	}

	.circle-1 {
		border-width: 8px !important;
	}
}

@media (min-width: 480px) {
	.img-wrapper {
		width: 220px;
		height: 220px;
	}

	.circle-1 {
		border-width: 9px !important;
	}
}

@media (min-width: 650px) {
	.img-wrapper {
		width: 300px;
		height: 300px;
	}

	.circle-1 {
		border-width: 12px !important;
	}
}

.screen4-content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.screen4-title {
	font-size: 35px;
	line-height: 40px;
	text-align: center;
	color: #ffd1eb;
}

:root {
	--img-width: 90px;

	--wrapper1-width: calc(var(--img-width) * 0.85);
	--wrapper1-offset: calc(var(--wrapper1-width) * 0.75);

	--wrapper2-width: calc(var(--img-width) * 0.85 * 0.85);
	--wrapper2-offset: calc(
		var(--wrapper1-offset) + var(--wrapper2-width) * 0.75
	);

	--wrapper3-width: calc(var(--img-width) * 0.85 * 0.85 * 0.85);
	--wrapper3-offset: calc(
		var(--wrapper2-offset) + (var(--wrapper3-width) * 0.75)
	);

	--wrapper4-width: calc(var(--img-width) * 0.85 * 0.85 * 0.85 * 0.85);
	--wrapper4-offset: calc(
		var(--wrapper3-offset) + (var(--wrapper4-width) * 0.75)
	);

	--width-test: calc(var(--wrapper4-width) + var(--wrapper4-offset));
}

.artist-covers {
	position: relative;
	width: calc(calc(90px * 0.85 * 0.85 * 0.85 * 0.85) + calc(
		calc(
		calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	) + (calc(90px * 0.85 * 0.85 * 0.85) * 0.75)
	) + (calc(90px * 0.85 * 0.85 * 0.85 * 0.85) * 0.75)
	));
	width: var(--width-test);
	height: 90px;
	height: var(--img-width);
	display: flex;
	align-items: center;
	margin-top: 30px;
}

.overlap-img-wrapper {
	width: 90px;
	width: var(--img-width);
	height: 90px;
	height: var(--img-width);
	position: absolute;
	opacity: 0;
}

.overlap-img-wrapper-1 {
	width: 90px;
	width: var(--img-width);
	height: 90px;
	height: var(--img-width);
	z-index: 5;
	border: 5px solid #1200ff;
	opacity: 0;
	-webkit-animation: cascade-1 0.5s ease-in-out forwards;
	        animation: cascade-1 0.5s ease-in-out forwards;
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}

@-webkit-keyframes cascade-1 {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes cascade-1 {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.overlap-img-wrapper-2 {
	width: calc(90px * 0.85);
	width: var(--wrapper1-width);
	height: calc(90px * 0.85);
	height: var(--wrapper1-width);
	/* transform: translateX(var(--wrapper1-offset));  */
	z-index: 4;
	border: 4px solid #f15aff;
	-webkit-animation: cascade-2 0.5s ease-in-out forwards;
	        animation: cascade-2 0.5s ease-in-out forwards;
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}

@-webkit-keyframes cascade-2 {
	from {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
		opacity: 0;
	}
	to {
		-webkit-transform: translateX(calc(calc(90px * 0.85) * 0.75));
		        transform: translateX(calc(calc(90px * 0.85) * 0.75));
		-webkit-transform: translateX(var(--wrapper1-offset));
		        transform: translateX(var(--wrapper1-offset));
		opacity: 1;
	}
}

@keyframes cascade-2 {
	from {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
		opacity: 0;
	}
	to {
		-webkit-transform: translateX(calc(calc(90px * 0.85) * 0.75));
		        transform: translateX(calc(calc(90px * 0.85) * 0.75));
		-webkit-transform: translateX(var(--wrapper1-offset));
		        transform: translateX(var(--wrapper1-offset));
		opacity: 1;
	}
}

.overlap-img-wrapper-3 {
	width: calc(90px * 0.85 * 0.85);
	width: var(--wrapper2-width);
	height: calc(90px * 0.85 * 0.85);
	height: var(--wrapper2-width);
	/* transform: translateX(var(--wrapper2-offset));  */
	z-index: 3;
	border: 4px solid #6826b2;
	-webkit-animation: cascade-3 0.5s ease-in-out forwards;
	        animation: cascade-3 0.5s ease-in-out forwards;
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}

@-webkit-keyframes cascade-3 {
	from {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
		opacity: 0;
	}
	to {
		-webkit-transform: translateX(calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	));
		        transform: translateX(calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	));
		-webkit-transform: translateX(var(--wrapper2-offset));
		        transform: translateX(var(--wrapper2-offset));
		opacity: 1;
	}
}

@keyframes cascade-3 {
	from {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
		opacity: 0;
	}
	to {
		-webkit-transform: translateX(calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	));
		        transform: translateX(calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	));
		-webkit-transform: translateX(var(--wrapper2-offset));
		        transform: translateX(var(--wrapper2-offset));
		opacity: 1;
	}
}

.overlap-img-wrapper-4 {
	width: calc(90px * 0.85 * 0.85 * 0.85);
	width: var(--wrapper3-width);
	height: calc(90px * 0.85 * 0.85 * 0.85);
	height: var(--wrapper3-width);
	/* transform: translateX(var(--wrapper3-offset));  */
	z-index: 2;
	border: 3px solid #d30f41;
	-webkit-animation: cascade-4 0.5s ease-in-out forwards;
	        animation: cascade-4 0.5s ease-in-out forwards;
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}

@-webkit-keyframes cascade-4 {
	from {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
		opacity: 0;
	}
	to {
		-webkit-transform: translateX(calc(
		calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	) + (calc(90px * 0.85 * 0.85 * 0.85) * 0.75)
	));
		        transform: translateX(calc(
		calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	) + (calc(90px * 0.85 * 0.85 * 0.85) * 0.75)
	));
		-webkit-transform: translateX(var(--wrapper3-offset));
		        transform: translateX(var(--wrapper3-offset));
		opacity: 1;
	}
}

@keyframes cascade-4 {
	from {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
		opacity: 0;
	}
	to {
		-webkit-transform: translateX(calc(
		calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	) + (calc(90px * 0.85 * 0.85 * 0.85) * 0.75)
	));
		        transform: translateX(calc(
		calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	) + (calc(90px * 0.85 * 0.85 * 0.85) * 0.75)
	));
		-webkit-transform: translateX(var(--wrapper3-offset));
		        transform: translateX(var(--wrapper3-offset));
		opacity: 1;
	}
}

.overlap-img-wrapper-5 {
	width: calc(90px * 0.85 * 0.85 * 0.85 * 0.85);
	width: var(--wrapper4-width);
	height: calc(90px * 0.85 * 0.85 * 0.85 * 0.85);
	height: var(--wrapper4-width);
	/* transform: translateX(var(--wrapper4-offset));  */
	z-index: 1;
	border: 2px solid #e593bf;
	-webkit-animation: cascade-5 0.5s ease-in-out forwards;
	        animation: cascade-5 0.5s ease-in-out forwards;
	-webkit-animation-delay: 0.3s;
	        animation-delay: 0.3s;
}

@-webkit-keyframes cascade-5 {
	from {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
		opacity: 0;
	}
	to {
		-webkit-transform: translateX(calc(
		calc(
		calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	) + (calc(90px * 0.85 * 0.85 * 0.85) * 0.75)
	) + (calc(90px * 0.85 * 0.85 * 0.85 * 0.85) * 0.75)
	));
		        transform: translateX(calc(
		calc(
		calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	) + (calc(90px * 0.85 * 0.85 * 0.85) * 0.75)
	) + (calc(90px * 0.85 * 0.85 * 0.85 * 0.85) * 0.75)
	));
		-webkit-transform: translateX(var(--wrapper4-offset));
		        transform: translateX(var(--wrapper4-offset));
		opacity: 1;
	}
}

@keyframes cascade-5 {
	from {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
		opacity: 0;
	}
	to {
		-webkit-transform: translateX(calc(
		calc(
		calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	) + (calc(90px * 0.85 * 0.85 * 0.85) * 0.75)
	) + (calc(90px * 0.85 * 0.85 * 0.85 * 0.85) * 0.75)
	));
		        transform: translateX(calc(
		calc(
		calc(
		calc(calc(90px * 0.85) * 0.75) + calc(90px * 0.85 * 0.85) * 0.75
	) + (calc(90px * 0.85 * 0.85 * 0.85) * 0.75)
	) + (calc(90px * 0.85 * 0.85 * 0.85 * 0.85) * 0.75)
	));
		-webkit-transform: translateX(var(--wrapper4-offset));
		        transform: translateX(var(--wrapper4-offset));
		opacity: 1;
	}
}

.song-data {
	display: grid;
	grid-template-rows: repeat(5, 1fr);
	grid-row-gap: 10px;
	row-gap: 10px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin-top: 20px;
	-webkit-animation: song-fade-in 0.5s ease-in-out 1s forwards;
	        animation: song-fade-in 0.5s ease-in-out 1s forwards;
	opacity: 0;
	width: 80%;
}

.single-song-data-wrapper {
	width: 100%;
	display: grid;
	grid-template-columns: 40px 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	color: #dafbff;
}

.song-index {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	font-weight: 700;
}

.single-song-text {
	overflow: hidden;
}

.single-song-name {
	font-size: 22px;
	line-height: 25px;
	font-weight: 700;
}

.single-artist-name {
	font-size: 14px;
}

@-webkit-keyframes song-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes song-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@media (min-width: 300px) {
	:root {
		--img-width: 100px;
	}

	.screen4-title {
		font-size: 40px;
		line-height: 45px;
	}
}

@media (min-width: 350px) {
	:root {
		--img-width: 120px;
	}

	.screen4-title {
		font-size: 45px;
		line-height: 50px;
	}
}

@media (min-width: 480px) {
	:root {
		--img-width: 150px;
	}

	.screen4-title {
		font-size: 50px;
		line-height: 55px;
	}

	.overlap-img-wrapper-1 {
		border-width: 8px;
	}
	.overlap-img-wrapper-2 {
		border-width: 7px;
	}
	.overlap-img-wrapper-3 {
		border-width: 7px;
	}
	.overlap-img-wrapper-4 {
		border-width: 5px;
	}
	.overlap-img-wrapper-5 {
		border-width: 5px;
	}

	.song-data {
		width: 70%;
	}

	.single-song-data-wrapper {
		grid-column-gap: 20px;
		-webkit-column-gap: 20px;
		        column-gap: 20px;
	}

	.song-index {
		font-size: 30px;
	}

	.single-song-name {
		font-size: 25px;
		line-height: 30px;
	}

	.single-artist-name {
		font-size: 16px;
	}
}

@media (min-width: 600px) {
	:root {
		--img-width: 180px;
	}

	.screen4-title {
		font-size: 50px;
		line-height: 55px;
	}

	.song-data {
		width: 70%;
	}

	.single-song-data-wrapper {
		grid-column-gap: 20px;
		-webkit-column-gap: 20px;
		        column-gap: 20px;
	}

	.song-index {
		font-size: 30px;
	}

	.single-song-name {
		font-size: 25px;
		line-height: 30px;
	}

	.single-artist-name {
		font-size: 16px;
	}
}

@media (min-width: 750px) {
	:root {
		--img-width: 200px;
	}

	.screen4-title {
		font-size: 60px;
		line-height: 65px;
	}

	.overlap-img-wrapper-1 {
		border-width: 10px;
	}
	.overlap-img-wrapper-2 {
		border-width: 9px;
	}
	.overlap-img-wrapper-3 {
		border-width: 9px;
	}
	.overlap-img-wrapper-4 {
		border-width: 7px;
	}
	.overlap-img-wrapper-5 {
		border-width: 7px;
	}

	.song-data {
		margin-top: 40px;
		width: 60%;
	}

	.single-song-data-wrapper {
		grid-column-gap: 20px;
		-webkit-column-gap: 20px;
		        column-gap: 20px;
	}

	.song-index {
		font-size: 30px;
	}

	.single-song-name {
		font-size: 25px;
		line-height: 30px;
	}

	.single-artist-name {
		font-size: 16px;
	}
}

/* screen 5 */
:root {
	--title-size: 40px;
}

.animation-page-percent {
	background-color: #72cfa9;
}

.screen5-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.percent-screen-title {
	text-align: center;
	font-size: 40px;
	font-size: var(--title-size);
	line-height: calc(40px + 2px);
	line-height: calc(var(--title-size) + 2px);
	text-transform: uppercase;
	color: #1e4651;
	z-index: 1;
}

@media (min-width: 300px) {
	:root {
		--title-size: 45px;
	}
}

@media (min-width: 350px) {
	:root {
		--title-size: 50px;
	}
}

@media (min-width: 400px) {
	:root {
		--title-size: 60px;
	}
}

@media (min-width: 1000px) {
	:root {
		--title-size: 75px;
	}
}

:root {
	--percent-size: 110px;
	--percent-offset: 2%;
}

.three-star {
	width: calc(110px / 3);
	width: calc(var(--percent-size) / 3);
	/* filter: invert(100%) sepia(0%) saturate(24%) hue-rotate(98deg) brightness(106%) contrast(108%); */
	position: absolute;
}

.three-star-left {
	left: -15%;
	bottom: -13%;
}

.three-star-right {
	right: -3%;
	top: -13%;
}

.match-percent-wrapper {
	position: relative;
	width: calc(110px * 1.5);
	width: calc(var(--percent-size) * 1.5);
	height: calc(110px);
	height: calc(var(--percent-size));
	line-height: 110px;
	line-height: var(--percent-size);
	margin-top: 10px;
	pointer-events: none;
	/* transform: scale(0); */
	opacity: 0;
	-webkit-animation: grow 1s ease-in-out 0.8s forwards;
	        animation: grow 1s ease-in-out 0.8s forwards;
}

@-webkit-keyframes grow {
	from {
		opacity: 0;
		/* transform: scale(0); */
	}
	to {
		opacity: 1;
		/* transform: scale(1); */
	}
}

@keyframes grow {
	from {
		opacity: 0;
		/* transform: scale(0); */
	}
	to {
		opacity: 1;
		/* transform: scale(1); */
	}
}

.match-percent {
	position: absolute;
	font-weight: 700;
	color: white;
}

.match-percent-1 {
	color: #1e4651;
	opacity: 0.5;
	left: calc(2% * 1);
	left: calc(var(--percent-offset) * 1);
	top: calc(2% * 1);
	top: calc(var(--percent-offset) * 1);
}

.match-percent-2 {
	color: #1e4651;
	opacity: 0.4;
	left: calc(2% * 2);
	left: calc(var(--percent-offset) * 2);
	top: calc(2% * 2);
	top: calc(var(--percent-offset) * 2);
}

.match-percent-3 {
	color: #1e4651;
	opacity: 0.3;
	left: calc(2% * 3);
	left: calc(var(--percent-offset) * 3);
	top: calc(2% * 3);
	top: calc(var(--percent-offset) * 3);
}

.match-percent-4 {
	color: #1e4651;
	opacity: 0.2;
	left: calc(2% * 4);
	left: calc(var(--percent-offset) * 4);
	top: calc(2% * 4);
	top: calc(var(--percent-offset) * 4);
}

.match-percent-5 {
	color: #1e4651;
	opacity: 0.1;
	left: calc(2% * 5);
	left: calc(var(--percent-offset) * 5);
	top: calc(2% * 5);
	top: calc(var(--percent-offset) * 5);
}

.percent {
	font-size: 110px;
	font-size: var(--percent-size);
}

.one-hundred {
	font-size: calc(110px * 0.8);
	font-size: calc(var(--percent-size) * 0.8);
}

.percent-symbol {
	font-size: calc(110px / 2);
	font-size: calc(var(--percent-size) / 2);
}

@media (min-width: 300px) {
	:root {
		--percent-size: 120px;
	}
}

@media (min-width: 350px) {
	:root {
		--percent-size: 140px;
	}
}

@media (min-width: 400px) {
	:root {
		--percent-size: 180px;
	}
	.one-hundred {
		font-size: calc(110px * 0.75);
		font-size: calc(var(--percent-size) * 0.75);
	}
}

@media (min-width: 480px) {
	:root {
		--percent-size: 200px;
	}
	.one-hundred {
		font-size: calc(110px * 0.8);
		font-size: calc(var(--percent-size) * 0.8);
	}
}

@media (min-width: 650px) {
	:root {
		--percent-size: 250px;
	}
}

@media (min-width: 1000px) {
	:root {
		--percent-size: 300px;
	}

	.one-hundred {
		font-size: 110px;
		font-size: var(--percent-size);
	}
}

/*  =====================
	=== Page Layout ===
	===================== */
:root {
	--spin-down-scale: 2.5;
	--spin-up-scale: 3;
}

.background-container {
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	min-height: var(--min-height);
	position: relative;
	width: 100%;
}

.background-wrapper {
	display: flex;
	height: 100%;
	justify-content: center;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	width: 100vw;
}

.absolute {
	pointer-events: none;
	position: absolute;
}

/*  =============
	=== Logos ===
	============= */
.logo-container {
	cursor: pointer;
	height: 40px;
	left: 30px;
	position: absolute;
	top: 25px;
	z-index: 1;
}

.vibecheck-logo {
	border: none;
	height: 40px;
	object-fit: cover;
}

.spotify-logo-wrapper {
	align-items: center;
	bottom: 25px;
	display: flex;
	height: 35px;
	position: absolute;
	right: 25px;
	width: 40px;
	z-index: 1;
}

.spotify-logo {
	width: 100%;
}

/*  =====================
	=== Stiple Images ===
	===================== */
.stiple-img {
	width: 100%;
}

.stiple-img-container-1 {
	bottom: -100px;
	left: -100px;
	-webkit-transform: rotate(-10deg);
	        transform: rotate(-10deg);
	width: 400px;
}

.stiple-img-container-2 {
	display: none;
	left: -50px;
	top: -50px;
	-webkit-transform: rotate(25deg);
	        transform: rotate(25deg);
	width: 250px;
}

.stiple-img-container-3 {
	right: -25px;
	top: -80px;
	-webkit-transform: rotate(-35deg);
	        transform: rotate(-35deg);
	width: 300px;
}

.stiple-img-container-4 {
	bottom: -50px;
	display: none;
	right: -80px;
	width: 250px;
}

/*  ===================
	=== Star Images ===
	=================== */
.star {
	width: 70px;
}

.star1 {
	right: 10px;
	top: 80px;
}

.star2 {
	bottom: 100px;
	left: 10px;
}

/*  ==========================
	=== Final Screen Burst ===
	========================== */
.spin-bursts-wrapper {
	align-items: center;
	-webkit-animation: burst-fade-in 0.8s ease-in-out 0.8s forwards;
	        animation: burst-fade-in 0.8s ease-in-out 0.8s forwards;
	bottom: 0;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	opacity: 0;
	position: relative;
	right: 0;
	top: 0;
	-webkit-transform: scale(3);
	        transform: scale(3);
	-webkit-transform: scale(var(--spin-up-scale));
	        transform: scale(var(--spin-up-scale));
	width: 100%;
}

.spin-burst {
	opacity: 0.2;
}

.spin-burst-1 {
	-webkit-animation: spin-1 10s linear infinite;
	        animation: spin-1 10s linear infinite;
	opacity: 0.2;
}

.spin-burst-2 {
	-webkit-animation: spin-2 10s linear infinite;
	        animation: spin-2 10s linear infinite;
	-webkit-transform: scale(2);
	        transform: scale(2);
}

.spin-burst-3 {
	-webkit-animation: spin-3 10s linear infinite;
	        animation: spin-3 10s linear infinite;
	-webkit-transform: scale(3);
	        transform: scale(3);
}

/*  ==================
	=== Animations ===
	================== */
@-webkit-keyframes burst-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes burst-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes spin-1 {
	from {
		-webkit-transform: scale(1.2) rotate(0);
		        transform: scale(1.2) rotate(0);
	}
	to {
		-webkit-transform: scale(1.2) rotate(90deg);
		        transform: scale(1.2) rotate(90deg);
	}
}

@keyframes spin-1 {
	from {
		-webkit-transform: scale(1.2) rotate(0);
		        transform: scale(1.2) rotate(0);
	}
	to {
		-webkit-transform: scale(1.2) rotate(90deg);
		        transform: scale(1.2) rotate(90deg);
	}
}

@-webkit-keyframes spin-2 {
	from {
		-webkit-transform: scale(2) rotate(0);
		        transform: scale(2) rotate(0);
	}
	to {
		-webkit-transform: scale(2) rotate(-90deg);
		        transform: scale(2) rotate(-90deg);
	}
}

@keyframes spin-2 {
	from {
		-webkit-transform: scale(2) rotate(0);
		        transform: scale(2) rotate(0);
	}
	to {
		-webkit-transform: scale(2) rotate(-90deg);
		        transform: scale(2) rotate(-90deg);
	}
}

@-webkit-keyframes spin-3 {
	from {
		-webkit-transform: scale(3) rotate(0);
		        transform: scale(3) rotate(0);
	}
	to {
		-webkit-transform: scale(3) rotate(90deg);
		        transform: scale(3) rotate(90deg);
	}
}

@keyframes spin-3 {
	from {
		-webkit-transform: scale(3) rotate(0);
		        transform: scale(3) rotate(0);
	}
	to {
		-webkit-transform: scale(3) rotate(90deg);
		        transform: scale(3) rotate(90deg);
	}
}

/*  =====================
	=== Media Queries ===
	===================== */
@media (min-width: 480px) {
	:root {
		--spin-up-scale: 2.5;
	}

	.stiple-img-container-2 {
		display: block;
	}
	.stiple-img-container-4 {
		display: block;
	}

	.star1 {
		right: 120px;
		top: 180px;
	}

	.star2 {
		left: 100px;
		bottom: 170px;
	}

	.spotify-logo-wrapper {
		height: 36px;
		width: 120px;
	}
}

@media (min-width: 650px) {
	:root {
		--spin-up-scale: 2;
	}

	.stiple-img-container-1 {
		left: 0px;
		bottom: -100px;
		width: 400px;
	}

	.stiple-img-container-2 {
		left: -50px;
		top: -40px;
		width: 300px;
	}

	.stiple-img-container-4 {
		right: 40px;
		width: 270px;
	}

	.star {
		width: 100px;
	}

	.star1 {
		right: 120px;
		top: 150px;
	}

	.star2 {
		left: 200px;
		bottom: 150px;
	}
}

@media (min-width: 750px) {
	.stiple-img-container-1 {
		left: -80px;
		bottom: -150px;
		width: 550px;
		-webkit-transform: rotate(-15deg);
		        transform: rotate(-15deg);
	}

	.stiple-img-container-2 {
		left: -50px;
		top: -80px;
		width: 400px;
	}
	.stiple-img-container-3 {
		right: -0px;
		top: -140px;
		width: 400px;
	}

	.stiple-img-container-4 {
		right: -40px;
		bottom: -80px;
		width: 350px;
	}
}

@media (min-width: 1000px) {
	.stiple-img-container-1 {
		left: 0px;
		width: 600px;
	}

	.stiple-img-container-2 {
		left: -50px;
		top: -80px;
		width: 450px;
	}
	.stiple-img-container-3 {
		right: -0px;
		top: -100px;
		width: 400px;
	}

	.stiple-img-container-4 {
		right: 50px;
		bottom: -50px;
		width: 400px;
	}

	.star {
		width: 120px;
	}

	.star1 {
		right: 200px;
		top: 50px;
	}

	.star2 {
		left: 120px;
		bottom: 150px;
	}
}

@media (min-width: 1200px) {
	:root {
		--spin-up-scale: 1;
	}
	.stiple-img-container-1 {
		left: 0px;
		bottom: -250px;
		width: 700px;
	}

	.stiple-img-container-2 {
		left: -100px;
		top: -80px;
		width: 550px;
	}
	.stiple-img-container-3 {
		right: -50px;
		top: -100px;
		width: 500px;
	}

	.stiple-img-container-4 {
		right: 50px;
		bottom: -150px;
		width: 500px;
	}
}

@media (min-width: 1600px) {
	.stiple-img-container-1 {
		left: 0px;
		bottom: -300px;
		width: 850px;
		-webkit-transform: rotate(-15deg);
		        transform: rotate(-15deg);
	}

	.stiple-img-container-2 {
		left: -100px;
		top: -100px;
		width: 600px;
		-webkit-transform: rotate(25deg);
		        transform: rotate(25deg);
	}

	.stiple-img-container-3 {
		right: -0px;
		top: -100px;
		width: 600px;
		-webkit-transform: rotate(-40deg);
		        transform: rotate(-40deg);
	}

	.stiple-img-container-4 {
		right: 100px;
		bottom: -200px;
		width: 600px;
	}

	.star {
		width: 125px;
	}

	.star1 {
		right: 380px;
		top: 80px;
	}

	.star2 {
		left: 200px;
		bottom: 150px;
	}
}

.stiple-img {
	/* transition: all 0.1s ease-in-out; */
	pointer-events: none;
}

.stiple-img-intro {
	-webkit-filter: invert(15%) sepia(43%) saturate(1420%) hue-rotate(193deg)
		brightness(95%) contrast(90%);
	        filter: invert(15%) sepia(43%) saturate(1420%) hue-rotate(193deg)
		brightness(95%) contrast(90%);
}

.stiple-img-genres {
	-webkit-filter: invert(22%) sepia(21%) saturate(914%) hue-rotate(235deg)
		brightness(94%) contrast(92%);
	        filter: invert(22%) sepia(21%) saturate(914%) hue-rotate(235deg)
		brightness(94%) contrast(92%);
}

.stiple-img-no-match {
	-webkit-filter: invert(36%) sepia(30%) saturate(527%) hue-rotate(172deg)
		brightness(94%) contrast(96%);
	        filter: invert(36%) sepia(30%) saturate(527%) hue-rotate(172deg)
		brightness(94%) contrast(96%);
}

.stiple-img-artists,
.stiple-img-but-here-artists,
.stiple-img-recommended-artists {
	-webkit-filter: invert(79%) sepia(30%) saturate(287%) hue-rotate(277deg)
		brightness(105%) contrast(99%);
	        filter: invert(79%) sepia(30%) saturate(287%) hue-rotate(277deg)
		brightness(105%) contrast(99%);
}

.stiple-img-percent {
	-webkit-filter: invert(14%) sepia(14%) saturate(4314%) hue-rotate(252deg)
		brightness(94%) contrast(96%);
	        filter: invert(14%) sepia(14%) saturate(4314%) hue-rotate(252deg)
		brightness(94%) contrast(96%);
}

.stiple-img-songs,
.stiple-img-but-here-songs,
.stiple-img-recommended-songs {
	-webkit-filter: invert(72%) sepia(4%) saturate(3225%) hue-rotate(190deg)
		brightness(122%) contrast(97%);
	        filter: invert(72%) sepia(4%) saturate(3225%) hue-rotate(190deg)
		brightness(122%) contrast(97%);
}

.stiple-img-percent {
	-webkit-filter: invert(21%) sepia(48%) saturate(639%) hue-rotate(146deg)
		brightness(87%) contrast(85%);
	        filter: invert(21%) sepia(48%) saturate(639%) hue-rotate(146deg)
		brightness(87%) contrast(85%);
}

.wave-top-container {
	width: 2200px;
	height: 100%;
	height: 300px;
}

.waves-top {
	position: absolute;
	top: 0;
	pointer-events: none;
	opacity: 0.1;
	z-index: 0;
	width: 100%;
	object-fit: cover;
}

.wave-bottom-container {
	width: 2800px;
	height: 100%;
}

.waves-bottom {
	position: absolute;
	bottom: 0;
	right: -100px;
	pointer-events: none;
	opacity: 0.1;
	z-index: 0;
	width: 100%;
	object-fit: cover;
}

.waves-top-no-match,
.waves-bottom-no-match,
.waves-top-percent,
.waves-bottom-percent {
	-webkit-filter: invert(97%) sepia(3%) saturate(0%) hue-rotate(253deg) brightness(103%)
		contrast(103%);
	        filter: invert(97%) sepia(3%) saturate(0%) hue-rotate(253deg) brightness(103%)
		contrast(103%);
	opacity: 0.15;
}

.fade-away {
	-webkit-animation: fade-away 0.8s ease-in-out 0.5s forwards;
	        animation: fade-away 0.8s ease-in-out 0.5s forwards;
}

@-webkit-keyframes fade-away {
	from {
		opacity: 0.15;
	}
	to {
		opacity: 0;
	}
}

@keyframes fade-away {
	from {
		opacity: 0.15;
	}
	to {
		opacity: 0;
	}
}

.percent-screen-extra-buttons-container {
	background-color: red;
	display: flex;
	justify-content: center;
	align-items: center;
}

.share-button {
	/* margin-top: ; */
	height: 45px;
	font-weight: 700;
	border-radius: 5px;
	border: none;
	padding-inline: 15px;
	cursor: pointer;
	transition: all 0.2s;
	background-color: white;
}

.share-button:hover {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
	background-color: white;
}

.modal {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	transition: all 0.3s;
}

.close-button {
	position: absolute;
	top: 4%;
	right: 4%;
	width: 11px;
	height: 11px;
	cursor: pointer;
}

.x-icon {
	width: 100%;
	height: 100%;
}

.modal-content {
	position: relative;
	padding: 20px;
	background-color: #d2f4e7;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 5%;
	margin-inline: 10px;
}

.rendered-img {
	object-fit: cover;
}

.modal-buttons-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 20px;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
}

.modal-button {
	background-color: #88d1b6;
	color: white;
	border: none;
	border-radius: 5px;
	padding: 10px 10px;
	font-weight: 600;
	cursor: pointer;
	transition: all 0.3s;
	font-size: 12px;
}

.modal-button:hover {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

.alt-social-buttons-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.react-share__ShareButton {
	display: flex;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	justify-content: center;
}

.hide {
	pointer-events: none;
	opacity: 0;
}

.plug-text {
	margin-top: 20px;
	color: #5a8694;
	font-weight: 300;
	font-size: 12px;
	text-align: center;
	width: 150px;
}

.plug-link {
	color: #1e4651;
}

.social-button {
	color: black !important;
}

@media (min-width: 300px) {
	.modal-content {
		padding: 25px;
	}

	.plug-text {
		width: 200px;
	}
}

@media (min-width: 350px) {
	.modal-content {
		padding: 30px;
	}
	.modal-button {
		padding: 10px 15px;
	}
}

@media (min-width: 400px) {
	.modal-content {
		padding: 35px;
	}

	.modal-buttons-container {
		margin-top: 35px;
	}

	.modal-button {
		font-size: 14px;
	}

	.plug-text {
		margin-top: 30px;
		font-size: 14px;
		width: 250px;
	}
}

@media (min-width: 450px) {
	.modal-content {
		padding: 40px;
	}

	.close-button {
		width: 15px;
		height: 15px;
	}
}

@media (min-width: 550px) {
	.modal-content {
		padding: 50px;
		border-radius: 3%;
	}

	.close-button {
		top: 3.5%;
		right: 3.5%;
		width: 18px;
		height: 18px;
	}

	.modal-buttons-container {
		margin-top: 50px;
	}

	.modal-button {
		font-size: 16px;
	}

	.plug-text {
		font-size: 14px;
		width: 280px;
	}
}

@media (min-width: 750px) {
	.close-button {
		top: 3%;
		right: 3%;
		width: 18px;
		height: 18px;
	}
}

@media (min-width: 1000px) {
	.close-button {
		top: 2.5%;
		right: 2.5%;
		width: 20px;
		height: 20px;
	}

	.plug-text {
		font-size: 14px;
		width: 500px;
	}
}

:root {
	--modal-img-border-width: 8px;
	--modal-img-border-radius: 10px;
}

.modal-img-wrapper {
	width: 180px;
	height: 180px;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-wrapper {
	width: 1000px;
	height: 1000px;
	overflow: hidden;
	background-color: #73cfaa;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 60px;
	position: absolute;
	bottom: -1000px;
	display: none;
	z-index: -1;
}

.modal-img-grid {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr;
	z-index: 1;
	position: relative;
}

.modal-title-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
}

.modal-title {
	font-size: 55px;
	text-transform: uppercase;
	line-height: 50px;
}

.modal-subtext {
	font-size: 25px;
}

.modal-user-images-percent {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.modal-user-images-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
}

.user-wrapper {
	color: white;
	font-weight: 600;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.modal-username {
	font-size: 25px;

	max-width: 215px;
	overflow: hidden;
	white-space: nowrap;
}

.outer-img-wrapper {
	border: 8px solid #e793bf;
	border: var(--modal-img-border-width) solid #e793bf;
	background-color: #e793bf;
	border-radius: 10px;
	border-radius: var(--modal-img-border-radius);
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.modal-user-img-wrapper {
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 190px;
	height: 190px;
	border: 8px solid #f5cee1;
	border: var(--modal-img-border-width) solid #f5cee1;
	border-radius: 10px;
	border-radius: var(--modal-img-border-radius);
}

.modal-user-img {
	position: absolute;
}

.modal-user-id {
	font-size: 22px;
	max-width: 215px;
	overflow: hidden;
	white-space: nowrap;
}

.modal-percentage-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.modal-match-percent-wrapper {
	font-weight: 700;
	color: white;
	display: flex;
}

.modal-match-title {
	font-size: 38px;
	font-weight: 800;
	text-transform: uppercase;
	color: #264e57;
}

.modal-match-percent {
	font-size: 200px;
	line-height: 200px;
}

.modal-match-percent-symbol {
	-webkit-transform: translateY(-10px);
	        transform: translateY(-10px);
	font-size: 100px;
	align-self: flex-end;
}

.modal-match-data {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 10px;
	-webkit-column-gap: 10px;
	        column-gap: 10px;
	margin-top: 40px;
}

.modal-matching-title {
	font-size: 26px;
	color: #264e57;
	text-transform: uppercase;
	font-weight: 800;
}

.list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	font-size: 25px;
	font-weight: 700;
	text-transform: capitalize;
	color: white;
	max-width: 260px;
	overflow: hidden;
}

.list-item {
	white-space: nowrap;
}

.spin-burst-modal {
	opacity: 0.2;
	position: absolute;
}

.spin-burst-modal-1 {
	-webkit-transform: scale(1.5);
	        transform: scale(1.5);
}

.spin-burst-modal-2 {
	-webkit-transform: scale(2.75);
	        transform: scale(2.75);
}

.spin-burst-modal-3 {
	-webkit-transform: scale(4);
	        transform: scale(4);
}

.stiple-modal {
	z-index: 1;
	position: absolute;
}

.stiple-modal-1 {
	left: -6%;
	bottom: -20%;
	width: 50%;
	-webkit-transform: rotate(-15deg);
	        transform: rotate(-15deg);
}

.stiple-modal-2 {
	left: -6%;
	top: -3%;
	width: 30%;
	-webkit-transform: rotate(20deg);
	        transform: rotate(20deg);
}

.stiple-modal-3 {
	right: -1%;
	top: -5%;
	width: 28%;
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
}

.stiple-modal-4 {
	right: -1%;
	bottom: -8%;
	width: 32%;
}

@media (min-width: 300px) {
	.modal-img-wrapper {
		width: 225px;
		height: 225px;
	}
}

@media (min-width: 350px) {
	.modal-img-wrapper {
		width: 250px;
		height: 250px;
	}
}

@media (min-width: 400px) {
	.modal-img-wrapper {
		width: 275px;
		height: 275px;
	}
}

@media (min-width: 450px) {
	.modal-img-wrapper {
		width: 325px;
		height: 325px;
	}
}

@media (min-width: 550px) {
	.modal-img-wrapper {
		width: 400px;
		height: 400px;
	}
}

@media (min-width: 650px) {
	.modal-img-wrapper {
		width: 450px;
		height: 450px;
	}
}

@media (min-width: 750px) {
	.modal-img-wrapper {
		width: 500px;
		height: 500px;
	}
}

@media (min-width: 1000px) {
	.modal-img-wrapper {
		width: 600px;
		height: 600px;
	}
}

.spinner-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 27px;
	height: 27px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 20px;
	height: 20px;
	margin: 3px;
	border: 3px solid rgb(200, 200, 200);
	border-radius: 50%;
	-webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: rgb(200, 200, 200) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	-webkit-animation-delay: -0.45s;
	        animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	-webkit-animation-delay: -0.3s;
	        animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	-webkit-animation-delay: -0.15s;
	        animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes lds-ring {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.compare-page {
	background-color: #e23838;
}

.compare-content {
	text-align: center;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding-inline: 20px;
}

.compare-heading {
	font-size: 45px;
	line-height: 50px;
	color: white;
	height: 100px;
}

.id-container {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
}

.compare-your-id {
	font-size: 20px;
	color: white;
	cursor: pointer;
}

.compare-subheading {
	font-size: 16px;
	color: white;
	padding-inline: 20px;
}

.edit-button {
	width: 30px;
	height: 30px;
	cursor: pointer;
	margin-left: 10px;
	transition: all 0.1s ease-in-out;
}

.edit-button:hover {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

@media screen and (min-width: 350px) {
	.compare-heading {
		font-size: 55px;
		line-height: 60px;
		height: 125px;
	}

	.compare-your-id {
		font-size: 25px;
	}

	.compare-subheading {
		font-size: 20px;
	}
}

@media screen and (min-width: 480px) {
	.compare-heading {
		font-size: 70px;
		line-height: 70px;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}

	.compare-your-id {
		font-size: 25px;
	}

	.compare-subheading {
		font-size: 20px;
	}
}

@media screen and (min-width: 650px) {
	.compare-heading {
		font-size: 70px;
	}

	.compare-subheading {
		font-size: 22px;
	}

	.compare-your-id {
		font-size: 35px;
	}
}

.profile-img-wrapper {
	display: flex;
	justify-content: center;
	margin-block: 50px;
}

.compare-img-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	position: relative;
}

.compare-img-left {
	object-fit: cover;
	position: absolute;
	width: 100%;
}

.compare-img-right {
	position: absolute;
}

.compare-img-left-wrapper {
	-webkit-transform: translateX(20px);
	        transform: translateX(20px);
	z-index: 1;
}

.compare-img-right-wrapper {
	-webkit-transform: translateX(-20px);
	        transform: translateX(-20px);
}

@media screen and (min-width: 300px) {
	.compare-img-wrapper {
		width: 120px;
		height: 120px;
	}
}

@media screen and (min-width: 350px) {
	.compare-img-wrapper {
		width: 150px;
		height: 150px;
	}
}

@media screen and (min-width: 480px) {
	.compare-img-wrapper {
		width: 200px;
		height: 200px;
	}
}

.delete-account-modal-container {
	position: absolute;
	width: 100%;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
}

.delete-account-modal {
	max-width: 600px;
	width: 100%;
	background-color: #e23838;
	border-radius: 10px;
	position: relative;
	padding: 35px;
}

.error-icon-wrapper {
	display: flex;
	justify-content: center;
}

.error-icon {
	height: 80px;
}

.delete-account-heading {
	text-align: center;
	font-size: 30px;
	font-weight: 700;
	color: white;
	margin-block: 20px;
}

.delete-account-subtext {
	text-align: center;
	font-size: 18px;
	color: white;
}

.delete-account-modal-buttons-wrapper {
	display: flex;
	justify-content: center;
	grid-gap: 10px;
	gap: 10px;
	margin-top: 35px;
}

@media (max-width: 300px) {
	.delete-account-modal-buttons-wrapper {
		justify-content: center;
		align-items: center;
		flex-direction: column-reverse;
		margin-top: 35px;
	}

	.delete-account-button {
		margin-top: 0;
	}
}

.invite-wrapper {
	z-index: 3;
}

.invite-button {
	width: 125px;
	margin-top: 25px;
}

.invite-modal-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
}

.invite-modal {
	max-width: 600px;
	width: 100%;
	background-color: #e23838;
	border-radius: 10px;
	position: relative;
	padding: 35px;
}

.invite-heading-wrapper {
	display: flex;
	align-items: center;
}

.envelope-icon-wrapper {
	width: 30px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin-right: 10px;
}

.invite-username-title {
	font-size: 18px;
	color: white;
	font-weight: 600;
	text-align: left;
}

.invite-input-wrapper {
	width: 100%;
	background-color: aqua;
	display: flex;
	margin-top: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	background-color: #c62a2a;
	border-radius: 5px;
	color: white;
	width: 100%;
}

.invite-input {
	width: 100%;
	height: 45px;
	border: none;
	font-size: 18px;
	background-color: transparent;
	color: white;
	padding-left: 10px;
}

.invite-modal-subtext-wrapper {
	margin-top: 30px;
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
}

.copy-button-wrapper {
	width: 45px;
	height: 45px;
	padding: 10px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #cd3333;
}

.invite-modal-buttons-wrapper {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	margin-left: auto;
	flex-wrap: wrap-reverse;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media (min-width: 450px) {
	.invite-modal-buttons-wrapper {
		flex-direction: row;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.invite-modal-subtext-wrapper {
		flex-direction: row;
	}

	.invite-username-title {
		font-size: 30px;
	}
}

.temp-wrapper{
    display: flex;
    align-items: center;
    -webkit-animation: fade-out 0.2s ease-in-out 1.8s forwards;
            animation: fade-out 0.2s ease-in-out 1.8s forwards;
    margin-top: 20px;
}

.check-wrapper{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.temp-alert{
    color: white;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    
}

@-webkit-keyframes fade-out {
    from {opacity: 1;}
    to {opacity: 0;}
  }

@keyframes fade-out {
    from {opacity: 1;}
    to {opacity: 0;}
  }


  @media(min-width: 450px) {
    .temp-wrapper{
        margin-top: 0;
    }
    
}
.profile-dropdown-container {
	position: absolute;
	top: 25px;
	right: 30px;
	z-index: 2;
}

.profile-dropdown-image-wrapper {
	border-radius: 50%;
	height: 45px;
	width: 45px;
	overflow: hidden;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.user-avatar {
	pointer-events: none;
}

.profile-dropdown {
	position: absolute;
	right: 0px;
	margin-top: 10px;
	background-color: #c62a2a;
	color: white;
	font-size: 16px;
	font-weight: 500;
	box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.4);
	border-radius: 5px;
	overflow: hidden;
}

.dropdown-buttons-wrapper {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.dropdown-button {
	white-space: nowrap;
	padding: 12px 16px;
	cursor: pointer;
	border-top: 1px solid #e94343;
	display: flex;
	display: flex;
	align-items: center;
}

.dropdown-button-icon {
	width: 15px;
	margin-right: 15px;
}

.trash-icon {
	width: 14px;
}

.dropdown-button:first-child {
	border-top: none;
}

.dropdown-button:hover {
	background-color: #cd3333;
}

.user-exists-modal-container {
	position: absolute;
	width: 100%;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
}

.user-exists-modal {
	max-width: 600px;
	width: 100%;
	background-color: #e23838;
	border-radius: 10px;
	position: relative;
	padding: 35px;
}

.error-icon-wrapper {
	display: flex;
	justify-content: center;
}

.error-icon {
	height: 80px;
}

.user-exists-modal-heading {
	text-align: center;
	font-size: 30px;
	font-weight: 700;
	color: white;
	margin-block: 20px;
}

.user-exists-modal-subtext {
	text-align: center;
	font-size: 18px;
	color: white;
}

.user-exists-modal-button {
	margin-top: 35px;
	display: block;
	margin-inline: auto;
}

.username-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.at-symbol {
	margin-left: 10px;
	margin-right: 5px;
}

.input-wrapper {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	background-color: #c62a2a;
	border-radius: 5px;
	color: white;
	width: 100%;
	max-width: 325px;
}

.bottom-line {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.username-input {
	width: 100%;
	height: 45px;
	border: none;
	font-size: 18px;
	background-color: transparent;
	color: white;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

.compare-button {
	border: 0;
	height: 45px;
	width: 55px;
	cursor: pointer;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	transition: 0.1s ease-in-out;
}

.compare-button:hover {
	background-color: #cd3333;
}

.compare-arrow {
	width: 25px;
}

.search-results-aligner {
	width: 100%;
	max-width: 325px;
	position: relative;
}

.search-results-wrapper {
	position: absolute;
	top: 0;
	width: 100%;
	background-color: #c62a2a;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	overflow-y: auto;
	overflow-x: hidden;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	z-index: 5;
	box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.4);
}

.default-result-img-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border: 2px solid #c62a2a;
	border-radius: 50%;
}

.result {
	display: flex;
	width: 100%;
	padding: 10px;
	cursor: pointer;
	position: relative;
	border-top: 1px solid #e94343;
}

.no-result {
	display: flex;
	width: 100%;
	padding: 10px;
	cursor: pointer;
	position: relative;
	border-top: 1px solid #e94343;
	color: white;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	padding-left: 10px;
}

.result:hover {
	background-color: #cd3333;
}

.result-img-wrapper {
	width: 62px;
	height: 62px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.result-img {
	object-fit: cover;
	position: absolute;
}

.result-info-wrapper {
	color: white;
	text-align: left;
	margin-left: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
}

.result-displayname {
	font-size: 20px;
	font-weight: 600;
}

.result-id {
	font-size: 14px;
	font-weight: 300;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.search-results-wrapper {
	scrollbar-width: auto;
	scrollbar-color: #ababab #ffffff;
}

/* Chrome, Edge, and Safari */
.search-results-wrapper::-webkit-scrollbar {
	width: 16px;
}

.search-results-wrapper::-webkit-scrollbar-track {
	background: #b92525;
	border-bottom-right-radius: 5px;
}

.search-results-wrapper::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 10px;
	border: 3px solid #b92525;
}

.username-modal-container {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
}

.hide-username-modal {
	background-color: red;
	display: none;
}

.change-username-input-container {
	max-width: 600px;
	width: 100%;
	background-color: #e23838;
	border-radius: 10px;
	position: relative;
	padding: 35px;
}

.change-username-title-wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	grid-gap: 10px;
	gap: 10px;
}

.edit-icon-wrapper {
	width: 30px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.change-username-title {
	font-size: 18px;
	color: white;
	font-weight: 600;
}

.change-username-input-wrapper {
	margin-top: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	background-color: #c62a2a;
	border-radius: 5px;
	color: white;
	width: 100%;
}

.change-username-input {
	width: 100%;
	height: 45px;
	border: none;
	font-size: 18px;
	background-color: transparent;
	color: white;
	padding-left: 10px;
}

.copy-vibe-id-icon-wrapper {
	width: 50px;
	height: 45px;
	padding: 12px;
	background-color: #b82424;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	cursor: pointer;
	transition: all 0.1s;
	display: flex;
	align-items: center;
}

.copy-vibe-id-icon-wrapper:hover {
	background-color: #ab1f1f;
}

.change-username-modal-subtext-wrapper {
	margin-top: 30px;
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
	justify-content: center;
}

.change-username-modal-button-wrapper {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	margin-left: auto;
	width: 100%;
	flex-wrap: wrap-reverse;
	justify-content: center;
}

.change-username-modal-subtext {
	color: white;
	font-size: 16px;
}

@media (min-width: 450px) {
	.change-username-title {
		font-size: 30px;
	}

	.change-username-modal-subtext {
		font-size: 20px;
	}

	.change-username-modal-button-wrapper {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.change-username-modal-subtext-wrapper {
		flex-direction: row;
	}
}

.error-animation {
	-webkit-animation: errors 0.4s ease-in-out;
	        animation: errors 0.4s ease-in-out;
}

@-webkit-keyframes errors {
	0% {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
	25% {
		-webkit-transform: translateX(20px);
		        transform: translateX(20px);
	}
	50% {
		-webkit-transform: translateX(-20px);
		        transform: translateX(-20px);
	}
	75% {
		-webkit-transform: translateX(20px);
		        transform: translateX(20px);
	}
	to {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

@keyframes errors {
	0% {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
	25% {
		-webkit-transform: translateX(20px);
		        transform: translateX(20px);
	}
	50% {
		-webkit-transform: translateX(-20px);
		        transform: translateX(-20px);
	}
	75% {
		-webkit-transform: translateX(20px);
		        transform: translateX(20px);
	}
	to {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
}

.homepage {
	background-color: #e23838;
	min-height: var(--min-height);
}

.homepage-content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-bottom: 35px;
}

.homepage-heading {
	font-size: 40px;
	color: white;
	height: 60px;
	font-family: 'Nunito', sans-serif;
	font-weight: 900;
}

.homepage-subtext {
	font-size: 16px;
	color: white;
	text-align: center;
	padding-inline: 25px;
}

.homepage-content .button {
	margin-top: 20px;
}

@media screen and (min-width: 400px) {
	.homepage-heading {
		font-size: 55px;
		height: 75px;
	}
}

@media screen and (min-width: 480px) {
	.homepage-heading {
		font-size: 75px;
		height: 100px;
	}

	.homepage-subtext {
		font-size: 18px;
	}
}

@media screen and (min-width: 1300px) {
	.homepage-heading {
		font-size: 100px;
		height: 125px;
	}

	.homepage-subtext {
		font-size: 20px;
	}
}

.not-enough-data-modal-container {
	position: absolute;
	width: 100%;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
}

.not-enough-data-modal {
	max-width: 600px;
	width: 100%;
	background-color: #e23838;
	border-radius: 10px;
	position: relative;
	padding: 35px;
}

.error-icon-wrapper {
	display: flex;
	justify-content: center;
}

.error-icon {
	height: 80px;
}

.not-enough-data-heading {
	text-align: center;
	font-size: 30px;
	font-weight: 700;
	color: white;
	margin-block: 20px;
}

.not-enough-data-subtext {
	text-align: center;
	font-size: 18px;
	color: white;
}

.not-enough-data-button {
	display: block;
	margin-inline: auto;
	margin-top: 30px !important;
	width: 125px !important;
}

:root {
	--min-height: 800px;
}

.App {
	font-family: 'Poppins', sans-serif;
	min-height: 800px;
	min-height: var(--min-height);
}

.button,
.cancel-button {
	min-width: 100px;
	padding: 10px 20px;
	font-size: 16px;
	border-radius: 8px;
	font-weight: 600;
	border: none;
	color: #e23838;
	background-color: white;
	cursor: pointer;
	transition: all ease-in-out 0.2s;
}

.button:hover,
.cancel-button:hover {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

.cancel-button {
	background-color: #e23838;
	color: white;
	border: 2px solid white;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.focus-outline:focus {
	outline-width: 2px;
	outline-style: dotted;
	outline-color: #000000;
	border-radius: 5px;
}

body::-webkit-scrollbar {
	width: 1em;
}

body::-webkit-scrollbar-thumb {
	background-color: rgb(220, 220, 220);
}

.content {
	z-index: 1;
}

body {
	overflow-x: hidden;
}

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	-webkit-text-decoration-skip: ink;
	        text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		-webkit-animation-duration: 0.01ms !important;
		        animation-duration: 0.01ms !important;
		-webkit-animation-iteration-count: 1 !important;
		        animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

