:root {
	--min-height: 800px;
}

.App {
	font-family: 'Poppins', sans-serif;
	min-height: var(--min-height);
}

.button,
.cancel-button {
	min-width: 100px;
	padding: 10px 20px;
	font-size: 16px;
	border-radius: 8px;
	font-weight: 600;
	border: none;
	color: #e23838;
	background-color: white;
	cursor: pointer;
	transition: all ease-in-out 0.2s;
}

.button:hover,
.cancel-button:hover {
	transform: scale(1.05);
}

.cancel-button {
	background-color: #e23838;
	color: white;
	border: 2px solid white;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.focus-outline:focus {
	outline-width: 2px;
	outline-style: dotted;
	outline-color: #000000;
	border-radius: 5px;
}

body::-webkit-scrollbar {
	width: 1em;
}

body::-webkit-scrollbar-thumb {
	background-color: rgb(220, 220, 220);
}

.content {
	z-index: 1;
}

body {
	overflow-x: hidden;
}

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}
