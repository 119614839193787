.invite-wrapper {
	z-index: 3;
}

.invite-button {
	width: 125px;
	margin-top: 25px;
}

.invite-modal-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
}

.invite-modal {
	max-width: 600px;
	width: 100%;
	background-color: #e23838;
	border-radius: 10px;
	position: relative;
	padding: 35px;
}

.invite-heading-wrapper {
	display: flex;
	align-items: center;
}

.envelope-icon-wrapper {
	width: 30px;
	height: fit-content;
	margin-right: 10px;
}

.invite-username-title {
	font-size: 18px;
	color: white;
	font-weight: 600;
	text-align: left;
}

.invite-input-wrapper {
	width: 100%;
	background-color: aqua;
	display: flex;
	margin-top: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	background-color: #c62a2a;
	border-radius: 5px;
	color: white;
	width: 100%;
}

.invite-input {
	width: 100%;
	height: 45px;
	border: none;
	font-size: 18px;
	background-color: transparent;
	color: white;
	padding-left: 10px;
}

.invite-modal-subtext-wrapper {
	margin-top: 30px;
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
}

.copy-button-wrapper {
	width: 45px;
	height: 45px;
	padding: 10px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #cd3333;
}

.invite-modal-buttons-wrapper {
	display: flex;
	gap: 10px;
	margin-left: auto;
	flex-wrap: wrap-reverse;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media (min-width: 450px) {
	.invite-modal-buttons-wrapper {
		flex-direction: row;
		width: fit-content;
	}

	.invite-modal-subtext-wrapper {
		flex-direction: row;
	}

	.invite-username-title {
		font-size: 30px;
	}
}
