/*  =====================
	=== Page Layout ===
	===================== */
:root {
	--spin-down-scale: 2.5;
	--spin-up-scale: 3;
}

.background-container {
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	min-height: var(--min-height);
	position: relative;
	width: 100%;
}

.background-wrapper {
	display: flex;
	height: 100%;
	justify-content: center;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	width: 100vw;
}

.absolute {
	pointer-events: none;
	position: absolute;
}

/*  =============
	=== Logos ===
	============= */
.logo-container {
	cursor: pointer;
	height: 40px;
	left: 30px;
	position: absolute;
	top: 25px;
	z-index: 1;
}

.vibecheck-logo {
	border: none;
	height: 40px;
	object-fit: cover;
}

.spotify-logo-wrapper {
	align-items: center;
	bottom: 25px;
	display: flex;
	height: 35px;
	position: absolute;
	right: 25px;
	width: 40px;
	z-index: 1;
}

.spotify-logo {
	width: 100%;
}

/*  =====================
	=== Stiple Images ===
	===================== */
.stiple-img {
	width: 100%;
}

.stiple-img-container-1 {
	bottom: -100px;
	left: -100px;
	transform: rotate(-10deg);
	width: 400px;
}

.stiple-img-container-2 {
	display: none;
	left: -50px;
	top: -50px;
	transform: rotate(25deg);
	width: 250px;
}

.stiple-img-container-3 {
	right: -25px;
	top: -80px;
	transform: rotate(-35deg);
	width: 300px;
}

.stiple-img-container-4 {
	bottom: -50px;
	display: none;
	right: -80px;
	width: 250px;
}

/*  ===================
	=== Star Images ===
	=================== */
.star {
	width: 70px;
}

.star1 {
	right: 10px;
	top: 80px;
}

.star2 {
	bottom: 100px;
	left: 10px;
}

/*  ==========================
	=== Final Screen Burst ===
	========================== */
.spin-bursts-wrapper {
	align-items: center;
	animation: burst-fade-in 0.8s ease-in-out 0.8s forwards;
	bottom: 0;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	opacity: 0;
	position: relative;
	right: 0;
	top: 0;
	transform: scale(var(--spin-up-scale));
	width: 100%;
}

.spin-burst {
	opacity: 0.2;
}

.spin-burst-1 {
	animation: spin-1 10s linear infinite;
	opacity: 0.2;
}

.spin-burst-2 {
	animation: spin-2 10s linear infinite;
	transform: scale(2);
}

.spin-burst-3 {
	animation: spin-3 10s linear infinite;
	transform: scale(3);
}

/*  ==================
	=== Animations ===
	================== */
@keyframes burst-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes spin-1 {
	from {
		transform: scale(1.2) rotate(0);
	}
	to {
		transform: scale(1.2) rotate(90deg);
	}
}

@keyframes spin-2 {
	from {
		transform: scale(2) rotate(0);
	}
	to {
		transform: scale(2) rotate(-90deg);
	}
}

@keyframes spin-3 {
	from {
		transform: scale(3) rotate(0);
	}
	to {
		transform: scale(3) rotate(90deg);
	}
}

/*  =====================
	=== Media Queries ===
	===================== */
@media (min-width: 480px) {
	:root {
		--spin-up-scale: 2.5;
	}

	.stiple-img-container-2 {
		display: block;
	}
	.stiple-img-container-4 {
		display: block;
	}

	.star1 {
		right: 120px;
		top: 180px;
	}

	.star2 {
		left: 100px;
		bottom: 170px;
	}

	.spotify-logo-wrapper {
		height: 36px;
		width: 120px;
	}
}

@media (min-width: 650px) {
	:root {
		--spin-up-scale: 2;
	}

	.stiple-img-container-1 {
		left: 0px;
		bottom: -100px;
		width: 400px;
	}

	.stiple-img-container-2 {
		left: -50px;
		top: -40px;
		width: 300px;
	}

	.stiple-img-container-4 {
		right: 40px;
		width: 270px;
	}

	.star {
		width: 100px;
	}

	.star1 {
		right: 120px;
		top: 150px;
	}

	.star2 {
		left: 200px;
		bottom: 150px;
	}
}

@media (min-width: 750px) {
	.stiple-img-container-1 {
		left: -80px;
		bottom: -150px;
		width: 550px;
		transform: rotate(-15deg);
	}

	.stiple-img-container-2 {
		left: -50px;
		top: -80px;
		width: 400px;
	}
	.stiple-img-container-3 {
		right: -0px;
		top: -140px;
		width: 400px;
	}

	.stiple-img-container-4 {
		right: -40px;
		bottom: -80px;
		width: 350px;
	}
}

@media (min-width: 1000px) {
	.stiple-img-container-1 {
		left: 0px;
		width: 600px;
	}

	.stiple-img-container-2 {
		left: -50px;
		top: -80px;
		width: 450px;
	}
	.stiple-img-container-3 {
		right: -0px;
		top: -100px;
		width: 400px;
	}

	.stiple-img-container-4 {
		right: 50px;
		bottom: -50px;
		width: 400px;
	}

	.star {
		width: 120px;
	}

	.star1 {
		right: 200px;
		top: 50px;
	}

	.star2 {
		left: 120px;
		bottom: 150px;
	}
}

@media (min-width: 1200px) {
	:root {
		--spin-up-scale: 1;
	}
	.stiple-img-container-1 {
		left: 0px;
		bottom: -250px;
		width: 700px;
	}

	.stiple-img-container-2 {
		left: -100px;
		top: -80px;
		width: 550px;
	}
	.stiple-img-container-3 {
		right: -50px;
		top: -100px;
		width: 500px;
	}

	.stiple-img-container-4 {
		right: 50px;
		bottom: -150px;
		width: 500px;
	}
}

@media (min-width: 1600px) {
	.stiple-img-container-1 {
		left: 0px;
		bottom: -300px;
		width: 850px;
		transform: rotate(-15deg);
	}

	.stiple-img-container-2 {
		left: -100px;
		top: -100px;
		width: 600px;
		transform: rotate(25deg);
	}

	.stiple-img-container-3 {
		right: -0px;
		top: -100px;
		width: 600px;
		transform: rotate(-40deg);
	}

	.stiple-img-container-4 {
		right: 100px;
		bottom: -200px;
		width: 600px;
	}

	.star {
		width: 125px;
	}

	.star1 {
		right: 380px;
		top: 80px;
	}

	.star2 {
		left: 200px;
		bottom: 150px;
	}
}
