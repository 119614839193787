.username-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.at-symbol {
	margin-left: 10px;
	margin-right: 5px;
}

.input-wrapper {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	background-color: #c62a2a;
	border-radius: 5px;
	color: white;
	width: 100%;
	max-width: 325px;
}

.bottom-line {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.username-input {
	width: 100%;
	height: 45px;
	border: none;
	font-size: 18px;
	background-color: transparent;
	color: white;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

.compare-button {
	border: 0;
	height: 45px;
	width: 55px;
	cursor: pointer;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	transition: 0.1s ease-in-out;
}

.compare-button:hover {
	background-color: #cd3333;
}

.compare-arrow {
	width: 25px;
}

.search-results-aligner {
	width: 100%;
	max-width: 325px;
	position: relative;
}
