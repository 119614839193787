.profile-dropdown-container {
	position: absolute;
	top: 25px;
	right: 30px;
	z-index: 2;
}

.profile-dropdown-image-wrapper {
	border-radius: 50%;
	height: 45px;
	width: 45px;
	overflow: hidden;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.user-avatar {
	pointer-events: none;
}

.profile-dropdown {
	position: absolute;
	right: 0px;
	margin-top: 10px;
	background-color: #c62a2a;
	color: white;
	font-size: 16px;
	font-weight: 500;
	box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.4);
	border-radius: 5px;
	overflow: hidden;
}

.dropdown-buttons-wrapper {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.dropdown-button {
	white-space: nowrap;
	padding: 12px 16px;
	cursor: pointer;
	border-top: 1px solid #e94343;
	display: flex;
	display: flex;
	align-items: center;
}

.dropdown-button-icon {
	width: 15px;
	margin-right: 15px;
}

.trash-icon {
	width: 14px;
}

.dropdown-button:first-child {
	border-top: none;
}

.dropdown-button:hover {
	background-color: #cd3333;
}
