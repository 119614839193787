.username-modal-container {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
}

.hide-username-modal {
	background-color: red;
	display: none;
}

.change-username-input-container {
	max-width: 600px;
	width: 100%;
	background-color: #e23838;
	border-radius: 10px;
	position: relative;
	padding: 35px;
}

.change-username-title-wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 10px;
}

.edit-icon-wrapper {
	width: 30px;
	height: fit-content;
}

.change-username-title {
	font-size: 18px;
	color: white;
	font-weight: 600;
}

.change-username-input-wrapper {
	margin-top: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	background-color: #c62a2a;
	border-radius: 5px;
	color: white;
	width: 100%;
}

.change-username-input {
	width: 100%;
	height: 45px;
	border: none;
	font-size: 18px;
	background-color: transparent;
	color: white;
	padding-left: 10px;
}

.copy-vibe-id-icon-wrapper {
	width: 50px;
	height: 45px;
	padding: 12px;
	background-color: #b82424;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	cursor: pointer;
	transition: all 0.1s;
	display: flex;
	align-items: center;
}

.copy-vibe-id-icon-wrapper:hover {
	background-color: #ab1f1f;
}

.change-username-modal-subtext-wrapper {
	margin-top: 30px;
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
	justify-content: center;
}

.change-username-modal-button-wrapper {
	display: flex;
	gap: 10px;
	margin-left: auto;
	width: 100%;
	flex-wrap: wrap-reverse;
	justify-content: center;
}

.change-username-modal-subtext {
	color: white;
	font-size: 16px;
}

@media (min-width: 450px) {
	.change-username-title {
		font-size: 30px;
	}

	.change-username-modal-subtext {
		font-size: 20px;
	}

	.change-username-modal-button-wrapper {
		width: fit-content;
	}

	.change-username-modal-subtext-wrapper {
		flex-direction: row;
	}
}

.error-animation {
	animation: errors 0.4s ease-in-out;
}

@keyframes errors {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(20px);
	}
	50% {
		transform: translateX(-20px);
	}
	75% {
		transform: translateX(20px);
	}
	to {
		transform: translateX(0);
	}
}
