.homepage {
	background-color: #e23838;
	min-height: var(--min-height);
}

.homepage-content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-bottom: 35px;
}

.homepage-heading {
	font-size: 40px;
	color: white;
	height: 60px;
	font-family: 'Nunito', sans-serif;
	font-weight: 900;
}

.homepage-subtext {
	font-size: 16px;
	color: white;
	text-align: center;
	padding-inline: 25px;
}

.homepage-content .button {
	margin-top: 20px;
}

@media screen and (min-width: 400px) {
	.homepage-heading {
		font-size: 55px;
		height: 75px;
	}
}

@media screen and (min-width: 480px) {
	.homepage-heading {
		font-size: 75px;
		height: 100px;
	}

	.homepage-subtext {
		font-size: 18px;
	}
}

@media screen and (min-width: 1300px) {
	.homepage-heading {
		font-size: 100px;
		height: 125px;
	}

	.homepage-subtext {
		font-size: 20px;
	}
}
