.compare-page {
	background-color: #e23838;
}

.compare-content {
	text-align: center;
	width: fit-content;
	padding-inline: 20px;
}

.compare-heading {
	font-size: 45px;
	line-height: 50px;
	color: white;
	height: 100px;
}

.id-container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.compare-your-id {
	font-size: 20px;
	color: white;
	cursor: pointer;
}

.compare-subheading {
	font-size: 16px;
	color: white;
	padding-inline: 20px;
}

.edit-button {
	width: 30px;
	height: 30px;
	cursor: pointer;
	margin-left: 10px;
	transition: all 0.1s ease-in-out;
}

.edit-button:hover {
	transform: scale(1.05);
}

@media screen and (min-width: 350px) {
	.compare-heading {
		font-size: 55px;
		line-height: 60px;
		height: 125px;
	}

	.compare-your-id {
		font-size: 25px;
	}

	.compare-subheading {
		font-size: 20px;
	}
}

@media screen and (min-width: 480px) {
	.compare-heading {
		font-size: 70px;
		line-height: 70px;
		height: fit-content;
	}

	.compare-your-id {
		font-size: 25px;
	}

	.compare-subheading {
		font-size: 20px;
	}
}

@media screen and (min-width: 650px) {
	.compare-heading {
		font-size: 70px;
	}

	.compare-subheading {
		font-size: 22px;
	}

	.compare-your-id {
		font-size: 35px;
	}
}
