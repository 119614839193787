/*  =====================
	=== Page Layout ===
	===================== */
.animation-page {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	width: 100%;
}

.animation-content-wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-inline: 20px;
	z-index: 1;
}

/*  ======================
	=== Artists Layout ===
	====================== */
.animation-page-artists,
.animation-page-but-here-artists,
.animation-page-recommended-artists {
	background-color: #8449a6;
}

/*  ====================
	=== Songs Layout ===
	==================== */
.animation-page-songs,
.animation-page-but-here-songs,
.animation-page-recommended-songs {
	background-color: #9a2f5a;
}

/*  =====================
	=== Button Layout ===
	===================== */
.buttons {
	bottom: 30px;
	column-gap: 10px;
	display: flex;
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: auto;
	margin-top: 30px;
	position: absolute;
	width: fit-content;
}

.buttons-three {
	grid-template-columns: 1fr 1fr 1fr;
	margin-top: 50px;
	position: static;
}

/*  ===============
	=== Buttons ===
	===============*/
.share-button {
	color: black;
}

.back-button {
	color: black !important;
	position: absolute;
	right: 30px;
	top: 25px;
}

.animation-button {
	align-items: center;
	background-color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	font-size: 16px;
	font-weight: 600;
	height: 45px;
	justify-content: center;
	min-width: 45px;
	padding: 0;
	transition: all 0.2s;
}

.redo-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.redo-button:hover,
.animation-button:hover {
	transform: scale(1.05);
}

/*  =============
	=== Icons ===
	============= */
.carret {
	width: 20px;
}

.redo-icon {
	height: 20px;
	width: 20px;
}

/*  ==================
	=== Animations ===
	================== */
.fade-in {
	animation: fade-in 0.5s ease-in-out forwards;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
