.img-container {
	width: 100%;
	margin-block: 8%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.left-img {
	transform: translateX(8%);
}

.right-img {
	transform: translateX(-8%);
	z-index: 1;
}

.profile-img {
	width: 100%;
}

.img-wrapper {
	width: 115px;
	height: 115px;
	position: relative;
}

.circle {
	position: absolute;
	width: 83%;
	height: 83%;
	background-color: #233360;
	border-radius: 50%;
	overflow: hidden;
}

/* Left */
.left-img .circle-1 {
	top: 0;
	right: 0;
	z-index: 5;
	border: 6px solid #fca5ff;
}

.left-img .circle-2 {
	top: 0px;
	right: 0px;
	z-index: 4;
	animation: left-telescope-2 0.1s ease-in-out forwards;
	animation-delay: 0.1s;
	opacity: 0;
}

@keyframes left-telescope-2 {
	from {
		top: 0px;
		right: 0px;
		opacity: 0;
	}
	to {
		top: 3.4%;
		right: 3.4%;
		opacity: 0.8;
	}
}

.left-img .circle-3 {
	top: 10px;
	right: 10px;
	z-index: 3;
	animation: left-telescope-3 0.1s ease-in-out forwards;
	animation-delay: 0.2s;
	opacity: 0;
}

@keyframes left-telescope-3 {
	from {
		top: 3.4%;
		right: 3.4%;
		opacity: 0;
	}
	to {
		top: 6.8%;
		right: 6.8%;
		opacity: 0.6;
	}
}

.left-img .circle-4 {
	top: 20px;
	right: 20px;
	z-index: 2;
	animation: left-telescope-4 0.1s ease-in-out forwards;
	animation-delay: 0.3s;
	opacity: 0;
}

@keyframes left-telescope-4 {
	from {
		top: 6.8%;
		right: 6.8%;
		opacity: 0;
	}
	to {
		top: 10.2%;
		right: 10.2%;
		opacity: 0.4;
	}
}

.left-img .circle-5 {
	top: 30px;
	right: 30px;
	opacity: 0.2;
	z-index: 1;
	animation: left-telescope-5 0.1s ease-in-out forwards;
	animation-delay: 0.4s;
	opacity: 0;
}

@keyframes left-telescope-5 {
	from {
		top: 10.2%;
		right: 10.2%;
		opacity: 0;
	}
	to {
		top: 13.6%;
		right: 13.6%;
		opacity: 0.2;
	}
}

.left-img .circle-6 {
	top: 40px;
	right: 40px;
	opacity: 0.1;
	animation: left-telescope-6 0.1s ease-in-out forwards;
	animation-delay: 0.5s;
	opacity: 0;
}

@keyframes left-telescope-6 {
	from {
		top: 13.6%;
		right: 13.6%;
		opacity: 0;
	}
	to {
		top: 17%;
		right: 17%;
		opacity: 0.1;
	}
}

/* Right */
.right-img .circle-1 {
	bottom: 0;
	left: 0;
	z-index: 5;
	border: 6px solid #feff97;
}

.right-img .circle-2 {
	bottom: 0px;
	left: 0px;
	z-index: 4;
	animation: right-telescope-2 0.1s ease-in-out forwards;
	animation-delay: 0.1s;
	opacity: 0;
}

@keyframes right-telescope-2 {
	from {
		bottom: 0px;
		left: 0px;
		opacity: 0;
	}
	to {
		bottom: 3.4%;
		left: 3.4%;
		opacity: 0.8;
	}
}

.right-img .circle-3 {
	bottom: 10px;
	left: 10px;
	z-index: 3;
	animation: right-telescope-3 0.1s ease-in-out forwards;
	animation-delay: 0.2s;
	opacity: 0;
}

@keyframes right-telescope-3 {
	from {
		bottom: 3.4%;
		left: 3.4%;
		opacity: 0;
	}
	to {
		bottom: 6.8%;
		left: 6.8%;
		opacity: 0.6;
	}
}

.right-img .circle-4 {
	bottom: 20px;
	left: 20px;
	z-index: 2;
	animation: right-telescope-4 0.1s ease-in-out forwards;
	animation-delay: 0.3s;
	opacity: 0;
}

@keyframes right-telescope-4 {
	from {
		bottom: 6.8%;
		left: 6.8%;
		opacity: 0;
	}
	to {
		bottom: 10.2%;
		left: 10.2%;
		opacity: 0.4;
	}
}

.right-img .circle-5 {
	bottom: 30px;
	left: 30px;
	z-index: 1;
	animation: right-telescope-5 0.1s ease-in-out forwards;
	animation-delay: 0.4s;
	opacity: 0;
}

@keyframes right-telescope-5 {
	from {
		bottom: 10.2%;
		left: 10.2%;
		opacity: 0;
	}
	to {
		bottom: 13.6%;
		left: 13.6%;
		opacity: 0.2;
	}
}

.right-img .circle-6 {
	bottom: 40px;
	left: 40px;
	opacity: 0.1;
	animation: right-telescope-6 0.1s ease-in-out forwards;
	animation-delay: 0.5s;
	opacity: 0;
}

@keyframes right-telescope-6 {
	from {
		bottom: 13.6%;
		left: 13.6%;
		opacity: 0;
	}
	to {
		bottom: 17%;
		left: 17%;
		opacity: 0.1;
	}
}

.star-container {
	width: 20%;
	position: absolute;
}

.left-img .star-container {
	top: 0;
	left: 0;
	/* filter: invert(94%) sepia(99%) saturate(441%) hue-rotate(340deg) brightness(107%) contrast(101%); */
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(173deg)
		brightness(102%) contrast(102%);
}

.right-img .star-container {
	bottom: 0;
	right: 0;
	transform: scaleX(-100%);
	/* filter: invert(89%) sepia(42%) saturate(4800%) hue-rotate(232deg) brightness(105%) contrast(101%); */
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(173deg)
		brightness(102%) contrast(102%);
}

@media (min-width: 300px) {
	.img-wrapper {
		width: 130px;
		height: 130px;
	}
}

@media (min-width: 350px) {
	.img-wrapper {
		width: 150px;
		height: 150px;
	}

	.circle-1 {
		border-width: 7px !important;
	}
}

@media (min-width: 400px) {
	.img-wrapper {
		width: 180px;
		height: 180px;
	}

	.circle-1 {
		border-width: 8px !important;
	}
}

@media (min-width: 480px) {
	.img-wrapper {
		width: 220px;
		height: 220px;
	}

	.circle-1 {
		border-width: 9px !important;
	}
}

@media (min-width: 650px) {
	.img-wrapper {
		width: 300px;
		height: 300px;
	}

	.circle-1 {
		border-width: 12px !important;
	}
}
