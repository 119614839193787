.user-exists-modal-container {
	position: absolute;
	width: 100%;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
}

.user-exists-modal {
	max-width: 600px;
	width: 100%;
	background-color: #e23838;
	border-radius: 10px;
	position: relative;
	padding: 35px;
}

.error-icon-wrapper {
	display: flex;
	justify-content: center;
}

.error-icon {
	height: 80px;
}

.user-exists-modal-heading {
	text-align: center;
	font-size: 30px;
	font-weight: 700;
	color: white;
	margin-block: 20px;
}

.user-exists-modal-subtext {
	text-align: center;
	font-size: 18px;
	color: white;
}

.user-exists-modal-button {
	margin-top: 35px;
	display: block;
	margin-inline: auto;
}
